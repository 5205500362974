import * as React from "react";
import * as ReactDOM from "react-dom";

import "./LicenseButton.scss";

const LicenseNoticeBody = function () {
  return (
    <div>
      The images of the maps are licensed to you under the following license
      agreement:
      <br />
      <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
        <img
          alt="Creative Commons License"
          style={{ borderWidth: 0 }}
          src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
        />
      </a>
      <br />
      Project Zomboid Map Project by Benjamin Schieder is licensed under a{" "}
      <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
        Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International
        License
      </a>
      .<br />
      <br />
      The source code of OpenSeaDragon is licensed under the New BSD license.
      Check <a href="http://openseadragon.github.io/">
        OpenSeaDragons website
      </a>{" "}
      for more information.
      <br />
      <br />
      The source code of Project Zomboid Map Project is considered to be in the
      public domain.
    </div>
  );
};

interface LicenseNoticeProps {
  close: () => void;
}

const LicenseNotice = function ({ close }: LicenseNoticeProps) {
  return (
    <div className="LicenseNotice">
      <div className="LicenseNotice__TitleBar">
        <span className="LicenseNotice__TitleBar__Title">Notice!</span>
        <button
          type="button"
          className="LicenseNotice__TitleBar__Close"
          onClick={close}
        >
          x
        </button>
      </div>
      <LicenseNoticeBody />
    </div>
  );
};

interface LicensePortalProps {
  close: () => void;
}

const LicensePortal = function (props: LicensePortalProps) {
  const domNode = document.getElementById("license-portal");
  if (domNode == null) {
    throw new Error("Unable to find #license-portal node");
  }

  return ReactDOM.createPortal(<LicenseNotice close={props.close} />, domNode);
};

const LicenseButton = function () {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <button
        type="button"
        id="btnLicense"
        className="LicenseButton"
        onClick={() => setVisible(!visible)}
      >
        <img
          alt="Creative Commons License"
          style={{ borderWidth: 0 }}
          src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
        />
      </button>
      {visible ? <LicensePortal close={() => setVisible(false)} /> : null}
    </>
  );
};

export default LicenseButton;
