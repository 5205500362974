{
  "map": { "id": 1, "name": "Survival", "version": "41" },
  "areas": [
    {
      "id": 9000,
      "name": "Louisville",
      "pois": [
        {
          "name": "Military Checkpoint",
          "id": 25491,
          "area_id": 9000,
          "x": 12512,
          "y": 4269
        },
        {
          "name": "St. Peregrin Hospital",
          "id": 27039,
          "area_id": 9000,
          "x": 12441,
          "y": 3695
        },
        {
          "name": "Fossoil Gas Station",
          "id": 27855,
          "area_id": 9000,
          "x": 12442,
          "y": 3540
        },
        {
          "name": "Ameriglobe Communications",
          "id": 20685,
          "area_id": 9000,
          "x": 12317,
          "y": 3538
        },
        {
          "name": "Leafhill Heights Elementary School",
          "id": 25678,
          "area_id": 9000,
          "x": 12341,
          "y": 3248
        },
        {
          "name": "Holy Haven Cemetary",
          "id": 21461,
          "area_id": 9000,
          "x": 12609,
          "y": 3376
        },
        {
          "name": "Leafhill Heights Offices",
          "id": 20925,
          "area_id": 9000,
          "x": 12248,
          "y": 3541
        },
        {
          "name": "Hit-Vids! VHS Store",
          "id": 28917,
          "area_id": 9000,
          "x": 12432,
          "y": 2819
        },
        {
          "name": "Oak Park Apartments",
          "id": 20791,
          "area_id": 9000,
          "x": 12126,
          "y": 2918
        },
        {
          "name": "Econorooms Motel",
          "id": 22238,
          "area_id": 9000,
          "x": 12435,
          "y": 2780
        },
        {
          "name": "Chapelmount Downs Horse Track",
          "id": 27325,
          "area_id": 9000,
          "x": 12220,
          "y": 2762
        },
        {
          "name": "Timmy's Pre-owned Cars",
          "id": 23676,
          "area_id": 9000,
          "x": 12376,
          "y": 2535
        },
        {
          "name": "Gigamart",
          "id": 26481,
          "area_id": 9000,
          "x": 12577,
          "y": 2548
        },
        { "name": "LSU", "id": 26866, "area_id": 9000, "x": 12405, "y": 2255 },
        {
          "name": "Scarlet Oak Distillery",
          "id": 21131,
          "area_id": 9000,
          "x": 12024,
          "y": 1988
        },
        {
          "name": "Fancy Waterfront House",
          "id": 20502,
          "area_id": 9000,
          "x": 12030,
          "y": 2585,
          "comment": "With a three-car garage"
        },
        {
          "name": "Fossoil HQ",
          "id": 25712,
          "area_id": 9000,
          "x": 12086,
          "y": 1616
        },
        {
          "name": "Kitten Knives",
          "id": 20008,
          "area_id": 9000,
          "x": 12180,
          "y": 1384
        },
        {
          "name": "Ready Prep Army Surplus Store",
          "id": 20863,
          "area_id": 9000,
          "x": 12219,
          "y": 1321
        },
        {
          "name": "Fossoil Gas Station",
          "id": 22068,
          "area_id": 9000,
          "x": 12269,
          "y": 1624
        },
        {
          "name": "Maps Unlimited",
          "id": 20476,
          "area_id": 9000,
          "x": 12231,
          "y": 1623
        },
        {
          "name": "Spiffo's Restaurant",
          "id": 26367,
          "area_id": 9000,
          "x": 12316,
          "y": 1934
        },
        {
          "name": "LBMW Network TV Station",
          "id": 26277,
          "area_id": 9000,
          "x": 12338,
          "y": 2053
        },
        {
          "name": "Jukebox Bowling",
          "id": 28338,
          "area_id": 9000,
          "x": 12446,
          "y": 2067
        },
        {
          "name": "Pizza Whirled",
          "id": 20989,
          "area_id": 9000,
          "x": 12485,
          "y": 2012
        },
        {
          "name": "Louisville Train Station",
          "id": 22903,
          "area_id": 9000,
          "x": 12718,
          "y": 2328
        },
        {
          "name": "Wellington Heights Golf Club",
          "id": 27416,
          "area_id": 9000,
          "x": 12972,
          "y": 2237
        },
        {
          "name": "Pharmahug",
          "id": 27652,
          "area_id": 9000,
          "x": 13122,
          "y": 2124
        },
        {
          "name": "Greene's Grocers",
          "id": 22798,
          "area_id": 9000,
          "x": 13137,
          "y": 2123
        },
        {
          "name": "Nat's Cafe",
          "id": 27763,
          "area_id": 9000,
          "x": 13171,
          "y": 2130
        },
        {
          "name": "Fossoil Baseball Field",
          "id": 25160,
          "area_id": 9000,
          "x": 13021,
          "y": 1579
        },
        {
          "name": "Bluegrass State High School",
          "id": 21126,
          "area_id": 9000,
          "x": 13047,
          "y": 1707
        },
        {
          "name": "American Tire",
          "id": 21909,
          "area_id": 9000,
          "x": 13109,
          "y": 1758
        },
        {
          "name": "Thumbs of Green Plant Store",
          "id": 20140,
          "area_id": 9000,
          "x": 13134,
          "y": 1779
        },
        {
          "name": "U-Store It",
          "id": 20993,
          "area_id": 9000,
          "x": 13121,
          "y": 1707
        },
        {
          "name": "Central Louisville Community Center",
          "id": 20585,
          "area_id": 9000,
          "x": 12847,
          "y": 1698
        },
        {
          "name": "LBMW Radio Station",
          "id": 25767,
          "area_id": 9000,
          "x": 12473,
          "y": 1771
        },
        {
          "name": "Medical Center+",
          "id": 21139,
          "area_id": 9000,
          "x": 12425,
          "y": 1764
        },
        {
          "name": "Louisville Police Department and Detention Services",
          "id": 23234,
          "area_id": 9000,
          "x": 12472,
          "y": 1609
        },
        {
          "name": "Courthouse",
          "id": 21371,
          "area_id": 9000,
          "x": 12473,
          "y": 1543
        },
        {
          "name": "City Hall",
          "id": 29551,
          "area_id": 9000,
          "x": 12568,
          "y": 1532
        },
        {
          "name": "Pawn $$$hop",
          "id": 29647,
          "area_id": 9000,
          "x": 12317,
          "y": 1332
        },
        {
          "name": "Fun Xtreme",
          "id": 27177,
          "area_id": 9000,
          "x": 12369,
          "y": 1327
        },
        {
          "name": "Nite Glo",
          "id": 23021,
          "area_id": 9000,
          "x": 12425,
          "y": 1330
        },
        {
          "name": "Zac's Hardware",
          "id": 26038,
          "area_id": 9000,
          "x": 12376,
          "y": 1381
        },
        {
          "name": "Phil's Fine & Fair Pawn Shop",
          "id": 25840,
          "area_id": 9000,
          "x": 12377,
          "y": 1467
        },
        {
          "name": "Book Naked",
          "id": 27182,
          "area_id": 9000,
          "x": 12377,
          "y": 1509
        },
        {
          "name": "Conven-u-mart",
          "id": 25710,
          "area_id": 9000,
          "x": 12376,
          "y": 1566
        },
        {
          "name": "Liquorty-Split",
          "id": 21012,
          "area_id": 9000,
          "x": 12619,
          "y": 2294
        },
        {
          "name": "Gas-2-Go Gas Station",
          "id": 26216,
          "area_id": 9000,
          "x": 12710,
          "y": 2237
        },
        {
          "name": "Fossoil Gas Station",
          "id": 24180,
          "area_id": 9000,
          "x": 13000,
          "y": 2165
        },
        {
          "name": "Louisville General Hospital",
          "id": 25159,
          "area_id": 9000,
          "x": 12935,
          "y": 2039
        },
        {
          "name": "Spiffo's Restaurant",
          "id": 20122,
          "area_id": 9000,
          "x": 12710,
          "y": 1619
        },
        {
          "name": "St. Michael's Cathedral",
          "id": 23374,
          "area_id": 9000,
          "x": 12770,
          "y": 1398
        },
        {
          "name": "The Grand Ohio Mall",
          "id": 27049,
          "area_id": 9000,
          "x": 13572,
          "y": 1409
        },
        {
          "name": "Stars & Stripes Gun Store",
          "id": 24083,
          "area_id": 9000,
          "x": 13554,
          "y": 1270,
          "comment": "Smells like freedom."
        },
        {
          "name": "Kegsplosion",
          "id": 21685,
          "area_id": 9000,
          "x": 13441,
          "y": 1293
        },
        {
          "name": "Hit Vids! VHS Store",
          "id": 28082,
          "area_id": 9000,
          "x": 13428,
          "y": 1292
        },
        {
          "name": "Window",
          "id": 29595,
          "area_id": 9000,
          "x": 13417,
          "y": 1292,
          "comment": "From Microsofts."
        },
        {
          "name": "Dotty 4 Donuts",
          "id": 26150,
          "area_id": 9000,
          "x": 13472,
          "y": 1352
        },
        {
          "name": "Knox Bank",
          "id": 26863,
          "area_id": 9000,
          "x": 13410,
          "y": 1352
        },
        {
          "name": "Jay's Chicken",
          "id": 26106,
          "area_id": 9000,
          "x": 13368,
          "y": 1352
        },
        {
          "name": "Spiffo's Restaurant",
          "id": 21997,
          "area_id": 9000,
          "x": 13304,
          "y": 1353
        },
        {
          "name": "Zac's Hardware",
          "id": 22198,
          "area_id": 9000,
          "x": 13312,
          "y": 1304
        },
        {
          "name": "5 Bux or Less",
          "id": 29049,
          "area_id": 9000,
          "x": 13321,
          "y": 1295
        },
        {
          "name": "Dressed to the 90s",
          "id": 20064,
          "area_id": 9000,
          "x": 13333,
          "y": 1294
        },
        {
          "name": "World of Books",
          "id": 28790,
          "area_id": 9000,
          "x": 13345,
          "y": 1295
        },
        {
          "name": "Greene's",
          "id": 28480,
          "area_id": 9000,
          "x": 13356,
          "y": 1294
        },
        {
          "name": "Pharmahug",
          "id": 26361,
          "area_id": 9000,
          "x": 13236,
          "y": 1289
        },
        {
          "name": "Your Closet",
          "id": 26902,
          "area_id": 9000,
          "x": 13222,
          "y": 1291
        },
        {
          "name": "Upscale Mobility Auto Dealership",
          "id": 22786,
          "area_id": 9000,
          "x": 13164,
          "y": 1396
        },
        {
          "name": "Timmy's Preowned Cars",
          "id": 22379,
          "area_id": 9000,
          "x": 13152,
          "y": 1455
        },
        {
          "name": "Food Market",
          "id": 28233,
          "area_id": 9000,
          "x": 12482,
          "y": 1935
        },
        {
          "name": "Holy Light Baptist Church",
          "id": 25725,
          "area_id": 9000,
          "x": 12410,
          "y": 1934
        },
        {
          "name": "School",
          "id": 23512,
          "area_id": 9000,
          "x": 12434,
          "y": 1861
        },
        {
          "name": "Jay's Chicken",
          "id": 24830,
          "area_id": 9000,
          "x": 12272,
          "y": 1381
        },
        {
          "name": "Jim's Autoshop",
          "id": 28061,
          "area_id": 9000,
          "x": 12234,
          "y": 1375
        },
        {
          "name": "Louisville Animal Shelter",
          "id": 24220,
          "area_id": 9000,
          "x": 12266,
          "y": 1434
        },
        {
          "name": "Sure Fitness & Boxing Club",
          "id": 24722,
          "area_id": 9000,
          "x": 12320,
          "y": 1433
        },
        {
          "name": "The Velvet Tassel",
          "id": 26584,
          "area_id": 9000,
          "x": 12322,
          "y": 1280
        },
        {
          "name": "Red Owl Inn",
          "id": 27970,
          "area_id": 9000,
          "x": 12354,
          "y": 1270
        },
        {
          "name": "Star-E-Plex Cinema",
          "id": 20683,
          "area_id": 9000,
          "x": 12475,
          "y": 1261
        },
        {
          "name": "Body Chisel Gym & Fitness",
          "id": 24917,
          "area_id": 9000,
          "x": 12621,
          "y": 1154
        },
        {
          "name": "Sanguine Spirits",
          "id": 25570,
          "area_id": 9000,
          "x": 12711,
          "y": 1154
        },
        {
          "name": "Louisville Boat Club",
          "id": 22895,
          "area_id": 9000,
          "x": 12765,
          "y": 1143
        },
        {
          "name": "Eden's Oasis",
          "id": 20753,
          "area_id": 9000,
          "x": 12791,
          "y": 1264
        },
        {
          "name": "Pharmahug",
          "id": 23646,
          "area_id": 9000,
          "x": 12810,
          "y": 1335
        },
        {
          "name": "Louisville Expo Center",
          "id": 23501,
          "area_id": 9000,
          "x": 12970,
          "y": 1324
        },
        {
          "name": "That one place",
          "id": 23777,
          "area_id": 9000,
          "x": 13425,
          "y": 1733,
          "comment": "With a lot of houses sharing a big backyard. You know the one I'm talking about, right?"
        },
        {
          "name": "That one place",
          "id": 24660,
          "area_id": 9000,
          "x": 14151,
          "y": 2628,
          "comment": "That's all fenced off. You know the one I'm talking about, right?"
        },
        {
          "name": "Louisville Music Festival 1993",
          "id": 27532,
          "area_id": 9000,
          "x": 13762,
          "y": 1953
        },
        {
          "name": "Fire Department",
          "id": 26614,
          "area_id": 9000,
          "x": 13711,
          "y": 1777
        },
        {
          "name": "U-Store It",
          "id": 29609,
          "area_id": 9000,
          "x": 13671,
          "y": 1600
        },
        {
          "name": "Gas-2-Go Gas Station",
          "id": 20656,
          "area_id": 9000,
          "x": 13616,
          "y": 1524
        },
        {
          "name": "Better Furnishings",
          "id": 23507,
          "area_id": 9000,
          "x": 13557,
          "y": 1523
        },
        {
          "name": "KnoxTalk Radio",
          "id": 24456,
          "area_id": 9000,
          "x": 13564,
          "y": 1584,
          "comment": "I want a Slurp Burp!"
        },
        {
          "name": "Finnegan Research Group",
          "id": 21930,
          "area_id": 9000,
          "x": 13601,
          "y": 1705
        },
        {
          "name": "Evergreen Public School",
          "id": 24596,
          "area_id": 9000,
          "x": 13586,
          "y": 2758
        },
        {
          "name": "Gigamart",
          "id": 27302,
          "area_id": 9000,
          "x": 13463,
          "y": 3049
        },
        { "name": "Mall", "id": 27599, "area_id": 9000, "x": 13347, "y": 3060 },
        {
          "name": "Burned Houses",
          "id": 28957,
          "area_id": 9000,
          "x": 13617,
          "y": 4081,
          "comment": "Shouldn't have built a house so close to the fence."
        },
        {
          "name": "Ivanov Factory & Showroom",
          "id": 22724,
          "area_id": 9000,
          "x": 12616,
          "y": 3622
        },
        {
          "name": "Line by Line Design",
          "id": 22076,
          "area_id": 9000,
          "x": 12555,
          "y": 3774
        },
        {
          "name": "Knox Pack Kitchens",
          "id": 29508,
          "area_id": 9000,
          "x": 12616,
          "y": 3754
        },
        {
          "name": "Mashie Signs & Painting",
          "id": 24681,
          "area_id": 9000,
          "x": 12613,
          "y": 3707
        },
        {
          "name": "Steely Revolve Metal Working",
          "id": 26904,
          "area_id": 9000,
          "x": 12588,
          "y": 3640
        },
        {
          "name": "Mass-Genfac Co.",
          "id": 21478,
          "area_id": 9000,
          "x": 12549,
          "y": 3648
        },
        {
          "name": "3N News Now Network TV Station",
          "id": 22585,
          "area_id": 9000,
          "x": 12641,
          "y": 1907
        },
        {
          "name": "Greene's",
          "id": 27552,
          "area_id": 9000,
          "x": 13513,
          "y": 2144
        },
        {
          "name": "Pizza Whirled",
          "id": 22252,
          "area_id": 9000,
          "x": 13522,
          "y": 2112
        },
        {
          "name": "Gas-2-Go Gas Station",
          "id": 25166,
          "area_id": 9000,
          "x": 13557,
          "y": 2139
        },
        {
          "name": "Milk 'n' More",
          "id": 23539,
          "area_id": 9000,
          "x": 13950,
          "y": 2239
        },
        {
          "name": "Electron House",
          "id": 26849,
          "area_id": 9000,
          "x": 13181,
          "y": 1642
        },
        {
          "name": "Sucrafruit Circus",
          "id": 26582,
          "area_id": 9000,
          "x": 13181,
          "y": 1636
        },
        {
          "name": "Fasionabelle",
          "id": 29945,
          "area_id": 9000,
          "x": 13181,
          "y": 1628
        },
        {
          "name": "Greene's",
          "id": 27122,
          "area_id": 9000,
          "x": 13180,
          "y": 1602
        },
        {
          "name": "Awl Work and Sew Play",
          "id": 20094,
          "area_id": 9000,
          "x": 13180,
          "y": 1558
        },
        {
          "name": "E.P. Tools",
          "id": 26726,
          "area_id": 9000,
          "x": 13180,
          "y": 1548
        },
        {
          "name": "Sunset Pines Funeral Home",
          "id": 24559,
          "area_id": 9000,
          "x": 13174,
          "y": 1525
        },
        {
          "name": "Turbon TV Station",
          "id": 23030,
          "area_id": 9000,
          "x": 13072,
          "y": 1472
        },
        {
          "name": "Eluee Arena",
          "id": 29061,
          "area_id": 9000,
          "x": 13047,
          "y": 1390
        },
        {
          "name": "The Real Sweat",
          "id": 21998,
          "area_id": 9000,
          "x": 12874,
          "y": 1376
        },
        {
          "name": "Cardinal Plaza",
          "id": 22296,
          "area_id": 9000,
          "x": 12848,
          "y": 1338
        },
        {
          "name": "Seat Yourself Furniture",
          "id": 24858,
          "area_id": 9000,
          "x": 12754,
          "y": 1339
        },
        {
          "name": "Sasha Sashay",
          "id": 29181,
          "area_id": 9000,
          "x": 12743,
          "y": 1338
        },
        {
          "name": "Hugo Plush",
          "id": 22790,
          "area_id": 9000,
          "x": 12781,
          "y": 1338
        },
        {
          "name": "Gas-2-Go Gas Station",
          "id": 29636,
          "area_id": 9000,
          "x": 13134,
          "y": 1327
        },
        {
          "name": "Scenic Mile Car Dealership",
          "id": 21926,
          "area_id": 9000,
          "x": 13250,
          "y": 1322
        },
        {
          "name": "Ice Ice Tasty",
          "id": 23422,
          "area_id": 9000,
          "x": 13243,
          "y": 1289
        },
        {
          "name": "Noteworthy",
          "id": 22879,
          "area_id": 9000,
          "x": 13216,
          "y": 1290
        },
        {
          "name": "Louisville Bruiser Lumber",
          "id": 29026,
          "area_id": 9000,
          "x": 13260,
          "y": 1249
        },
        {
          "name": "Hit Vids! VHS Store",
          "id": 23881,
          "area_id": 9000,
          "x": 12794,
          "y": 1212
        },
        {
          "name": "Evergreen Hill Community Center",
          "id": 21125,
          "area_id": 9000,
          "x": 13697,
          "y": 2795
        },
        {
          "name": "Knox Bank",
          "id": 21713,
          "area_id": 9000,
          "x": 13601,
          "y": 3020
        },
        {
          "name": "Martin's Essentials & Laundry Gas Station",
          "id": 23218,
          "area_id": 9000,
          "x": 12098,
          "y": 2102
        },
        {
          "name": "Food Market",
          "id": 26275,
          "area_id": 9000,
          "x": 13781,
          "y": 2540
        },
        {
          "name": "Police Outpost",
          "id": 25810,
          "area_id": 9000,
          "x": 13781,
          "y": 2558
        },
        {
          "name": "American Tire",
          "id": 20336,
          "area_id": 9000,
          "x": 13281,
          "y": 2963
        },
        {
          "name": "Med+u+Well",
          "id": 28637,
          "area_id": 9000,
          "x": 13228,
          "y": 2971
        },
        {
          "name": "Park Center",
          "id": 21771,
          "area_id": 9000,
          "x": 13389,
          "y": 2368
        },
        {
          "name": "Gas-2-Go Gas Station",
          "id": 25595,
          "area_id": 9000,
          "x": 13959,
          "y": 3275
        },
        {
          "name": "Milk'n'More",
          "id": 26872,
          "area_id": 9000,
          "x": 13961,
          "y": 3245
        },
        {
          "name": "A. A. Ron hunting Supply",
          "id": 28543,
          "area_id": 9000,
          "x": 13961,
          "y": 3230
        },
        {
          "name": "E.P. Tools",
          "id": 24123,
          "area_id": 9000,
          "x": 13962,
          "y": 3102
        }
      ]
    }
  ]
}
