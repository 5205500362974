import * as OpenSeaDragon from "openseadragon";
import { throttle } from "lodash-es";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { pixelToTile, tileToPixel } from "./coords";
import { buildPoiOptions } from "./poi";
import initSentry from "./sentry";
import App from "./App";

require("./openseadragon-svg-overlay");

/**
 * Error reporting
 */
initSentry();

/**
 * Housekeeping
 */
if (document.domain === "projectzomboid.gitlab.io") {
  throw new Error("Not running correctly in subdomain mode");
}

/**
 * Set up url-configurable env options
 */
function populateGet() {
  const obj = {};
  const params = window.location.search.slice(1).split("&");
  for (let i = 0, len = params.length; i < len; i++) {
    const keyVal = params[i].split("=");
    obj[decodeURIComponent(keyVal[0])] = decodeURIComponent(keyVal[1]);
  }
  return obj;
}

const queryParams = populateGet();
let GRID = false;
let BASE_URL = "https://pz-map-data.six.ph/file/pz-map-data/maps/"; // whoo saving money
if (queryParams.GRID !== undefined) {
  GRID = parseInt(queryParams.GRID, 10) === 1;
}
if (queryParams.USE_LOCALHOST !== undefined) {
  BASE_URL = "http://localhost:8000/";
}

/**
 * Application logic
 */

let viewer;
let baseLayer;
let tracker;
const MAP_VERSION = "v41-65";

const context = {
  coordinateToolActive: false,
};

function sourceForLayer(layerNumber) {
  return `${BASE_URL}${MAP_VERSION}-L${layerNumber}/map.xml`;
}

let activeLayerItems = [];

async function addLayer(layerNumber) {
  return new Promise((resolve, reject) => {
    viewer.addTiledImage({
      tileSource: sourceForLayer(layerNumber),
      opacity: 1,
      // width: 1486848 / viewer.source.width, // width of second layer
      // don't need to do anything fancy because we rendered the layers to be the same width
      width: 1,
      success: ({ item }) => resolve(item),
      error: (message) => reject(message),
    });
  });
}

async function changeLevel(choice) {
  const resetItems = (newItems) => {
    activeLayerItems.forEach((activeItem) =>
      viewer.world.removeItem(activeItem)
    );
    activeLayerItems = newItems;
  };
  try {
    if (choice === 0) {
      resetItems([]);
    } else {
      const item = await addLayer(choice);
      // Wait for items to load in before removing lower layers (makes it look smoother)
      await new Promise((resolve) => {
        item.addOnceHandler("fully-loaded-change", ({ fullyLoaded }) => {
          resolve(fullyLoaded);
        });
      });
      resetItems([item]);
    }
  } finally {
    document.getElementById("select-layer").disabled = false;
  }
}

const tileOverlayCanvas = document.createElement("canvas");
const tileOverlayContext = tileOverlayCanvas.getContext("2d");
tileOverlayCanvas.width = 128;
tileOverlayCanvas.height = 128;

function drawDiamond(ctx, width, height) {
  ctx.save();

  const lineWidth = 3;

  // Avoid stroking outside the square
  ctx.lineWidth = lineWidth * 2;
  ctx.strokeStyle = "rgb(0,255,0)";
  ctx.beginPath();
  ctx.moveTo(lineWidth, height / 2);
  ctx.lineTo(width / 2, height - lineWidth);
  ctx.lineTo(width - lineWidth, height / 2);
  ctx.lineTo(width / 2, lineWidth);
  ctx.closePath();
  ctx.stroke();

  /* debug square showing drawable bounds */
  if (GRID) {
    ctx.fillStyle = "rgba(0,255,0,0.1)";
    ctx.beginPath();
    ctx.rect(0, 0, 128, 128);
    ctx.fill();
  }
  ctx.restore();
}
drawDiamond(tileOverlayContext, 128, 64);

function updateCoords(e) {
  const viewportPoint = viewer?.viewport?.pointFromPixel(e.position);
  if (!viewportPoint) return;
  if (!baseLayer) return;

  if (!context.coordinateToolActive) return;

  const contentSize = baseLayer.getContentSize();
  const px = Math.floor(viewportPoint.x * contentSize.x);
  const py = Math.floor(viewportPoint.y * contentSize.x);

  const tile = pixelToTile(px, py);
  const tilePixel = tileToPixel(tile.x, tile.y);

  const cell = { x: Math.floor(tile.x / 300), y: Math.floor(tile.y / 300) };
  const rel = { x: tile.x % 300, y: tile.y % 300 };

  document.getElementById("tile-coords").textContent = `${tile.x}x${tile.y}`;
  document.getElementById("cell-coords").textContent = `${cell.x}x${cell.y}`;
  document.getElementById("rel-coords").textContent = `${rel.x}x${rel.y}`;

  if (GRID) {
    console.log(viewportPoint, { px, py }, tile);
  }

  const rect = viewer.viewport.imageToViewportRectangle(
    tilePixel.x - 64, // tileToPixel returns top middle of tile
    tilePixel.y,
    128,
    128
  );

  viewer.updateOverlay(tileOverlayCanvas, rect);
}

/**
 * URL hash setting and updating
 */
function changePositionByLink(viewport) {
  // #0.1234,0.1234,0.1234
  const m = window.location.hash.match(/^#([\d.]+),([\d.]+)(?:,([\d.]+))?$/);
  if (!m) {
    window.location.hash = "";
    return;
  }
  const x = parseFloat(m[1]);
  const y = parseFloat(m[2]);
  const zoom = parseFloat(m[3]);

  const point = new OpenSeaDragon.Point(x, y);
  viewport.panTo(point, true);
  viewport.zoomTo(zoom, null, true);
}

function updateHash({ eventSource }) {
  const { viewport } = eventSource;
  if (!viewport) return;

  const center = viewport.getCenter();
  const zoom = viewport.getZoom();

  const link = `${center.x.toFixed(5)},${center.y.toFixed(5)},${zoom.toFixed(
    0
  )}`;
  window.location.hash = link;
}

async function runOSD() {
  viewer = new OpenSeaDragon.Viewer({
    id: "map",
    debugMode: GRID,
    minScrollDeltaTime: 10,
    defaultZoomLevel: 1.2,
    maxImageCacheCount: 128, // reduced from default of 200
    immediateRender: true, // recommended for mobile
    // icons floating on map (zoom, home, fullscreen)
    prefixUrl: "https://map.projectzomboid.com/images/",
  });

  baseLayer = await addLayer(0);
  changePositionByLink(viewer.viewport); // need to wait for first layer to load

  // Connect mouse tracker
  tracker = new OpenSeaDragon.MouseTracker({
    element: "map",
    moveHandler: throttle(updateCoords, 1000 / 60), // limit to 60 fps
  });

  // Connect URL updater
  viewer.addHandler("animation-finish", updateHash);
  viewer.addOverlay(tileOverlayCanvas, new OpenSeaDragon.Rect(0, 0, 0, 0));

  // POI adder helper
  if (GRID) {
    viewer.addHandler("canvas-click", (e) => {
      if (!e.shift) {
        return;
      }
      e.preventDefaultAction = true;

      const viewportPoint = viewer.viewport.pointFromPixel(e.position);
      const contentSize = baseLayer.getContentSize();
      const px = Math.floor(viewportPoint.x * contentSize.x);
      const py = Math.floor(viewportPoint.y * contentSize.x);

      const tile = pixelToTile(px, py);

      const poiName = prompt("Enter a name: ");

      const poi = {
        name: poiName,
        id: 20000 + Math.floor(Math.random() * 10000),
        area_id: 9000,
        x: tile.x,
        y: tile.y,
      };
      console.log(JSON.stringify(poi) + ",");
      setTimeout(
        () => navigator.clipboard.writeText(JSON.stringify(poi) + ","),
        100
      );
    });
  }
}

function setCoordinateToolActive(active) {
  if (!active) {
    viewer.updateOverlay(tileOverlayCanvas, new OpenSeaDragon.Rect(0, 0, 0, 0));
  }
  context.coordinateToolActive = active;
}

document.addEventListener("DOMContentLoaded", () => {
  const osdPromise = runOSD();
  const getPois = async () => {
    await osdPromise;
    return buildPoiOptions(viewer.viewport);
  };

  /**
   * Footer reactivity
   */
  document.getElementById("closeFooter")?.addEventListener("click", () => {
    const footer = document.getElementById("footer");
    footer?.parentNode?.removeChild(footer);
  });

  ReactDOM.render(
    React.createElement(App, {
      getPois,
      setLayer: changeLevel,
      setCoordinateToolActive,

      /**
       * POIs
       */
      poiMount: document.getElementById("poiHolder"),

      /**
       * License
       */
      licenseMount: document.getElementById("buttonRoot"),

      /**
       * Sidebar controls
       */
      sidebarMount: document.getElementById("sidebarRoot"),

      viewer,
    }),
    document.getElementById("appRoot")
  );
});
