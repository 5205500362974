import * as OpenSeaDragon from "openseadragon";

import { tileToPixel } from "./coords";

import data from "./pois.json";
import data_2 from "./new_pois.json";

interface Poi {
  id: number;
  area_id: number;
  name: string;
  x: number;
  y: number;
  comment: string;
}

interface PoiArea {
  id: number;
  name: string;
  pois: Poi[];
}

interface PoiFile {
  map: unknown;
  areas: PoiArea[];
}

const RAW_POI_DATA = data as PoiFile;
const RAW_POI_DATA2 = data_2 as PoiFile;

/**
 * TODO
 * - get the other sizes of images ( and offsets)
 * - make it configurable (toggleable)
 * - render the labels
 */

// function newPOIMarker() {
//   const icon = document.createElement("button");
//   icon.type = "button";
//   icon.className = "link-button";
//   const image = document.createElement("img");
//   image.src = mapcircleblue_large;
//   image.className = "offsetlarge"; // fixes positioning
//   icon.appendChild(image);
//   return icon;
// }

export interface PoiOption {
  location: OpenSeaDragon.Point;
  placement: OpenSeaDragon.Placement;
  width: number;
  height: number;
  id: number;
  name: string;
  comment: string;
}

export function buildPoiOptions(viewport: OpenSeaDragon.Viewport): PoiOption[] {
  return (
    [...RAW_POI_DATA.areas, ...RAW_POI_DATA2.areas]
      .flatMap((area) =>
        area.pois.map((poi) => {
          // const element = newPOIMarker();
          const coords = tileToPixel(poi.x, poi.y);
          const location = viewport.imageToViewportCoordinates(
            coords.x,
            coords.y
          );

          return {
            // element,
            location,
            placement: OpenSeaDragon.Placement.CENTER,
            width: 0,
            height: 0,
            id: poi.id,
            name: poi.name,
            comment: poi.comment,
          };
        })
      )
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sort_stability
      .sort((poi1, poi2) => poi2.location.x - poi1.location.x)
      .sort((poi1, poi2) => poi2.location.y - poi1.location.y)
  );
}

export default function addPois(viewer: OpenSeaDragon.Viewer): void {
  buildPoiOptions(viewer.viewport).forEach((options) => {
    viewer.addOverlay(options);
  });
}
