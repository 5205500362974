/**
 * These is very similar to the values from addtocache.py. Not sure why it's slightly different LOL.
 * These match the values on map.projectzomboid.com
 */
const pixelToTileOffsetX = -672000; // = -667200 - 4800; //
const pixelToTileOffsetY = 249472; //  =  249600 - 128;

/**
 * Corresponds to the top (0 relative py) middle pixel of the tile
 */
export function tileToPixel(tileX: number, tileY: number) {
  const mx = (tileX + 1) * 64;
  const my = (tileY + 1) * 64;

  const adjustedX = mx - my;
  const adjustedY = (mx + my) / 2;

  const px = adjustedX - pixelToTileOffsetX;
  const py = adjustedY - pixelToTileOffsetY;

  // Note that this basically mirrors the calculations in addtocache.py
  //    px = tileX * 64 - tileY * 64;
  //    py = tileX * 32 + tileY * 32;

  return { x: px, y: py };
}

export function pixelToTile(px: number, py: number) {
  const adjustedX = px + pixelToTileOffsetX;
  const adjustedY = py + pixelToTileOffsetY;

  const mx = adjustedY + adjustedX / 2;
  const my = adjustedY - adjustedX / 2;

  const tileX = Math.floor(mx / 64) - 1; // Offset by 1, experimentally determined
  const tileY = Math.floor(my / 64) - 1;
  // console.log(`Mouse XxY: ${mx}x${my} Tile: ${tileX}x${tileY}`);
  return { x: tileX, y: tileY };
}
