{
  "map": { "id": 1, "name": "Survival", "version": "35" },
  "areas": [
    {
      "id": 1,
      "name": "Dixie",
      "pois": [
        {
          "id": 1,
          "area_id": 1,
          "name": "Barg-n-Clothes",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11599,
          "y": 8252,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 2,
          "area_id": 1,
          "name": "Cabin",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11243,
          "y": 8953,
          "score": 500,
          "user_id": 1,
          "comment": "Do you really want to be alone in the woods now? Without a well?",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 3,
          "area_id": 1,
          "name": "Diner",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11464,
          "y": 8809,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 4,
          "area_id": 1,
          "name": "Gas station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11508,
          "y": 8832,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 5,
          "area_id": 1,
          "name": "Gas-2-Go",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11603,
          "y": 8308,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 6,
          "area_id": 1,
          "name": "Picnic dining area",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11556,
          "y": 8858,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 7,
          "area_id": 1,
          "name": "Spiffo's",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11668,
          "y": 8302,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 8,
          "area_id": 1,
          "name": "Supermarket",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11670,
          "y": 8794,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 9,
          "area_id": 1,
          "name": "Trailer Park",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11493,
          "y": 8914,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 867,
          "area_id": 1,
          "name": "Molan's Used Cars",
          "created_at": "2017-12-20T18:26:38.389Z",
          "updated_at": "2018-05-11T11:11:44.549Z",
          "x": 11687,
          "y": 8367,
          "score": 20,
          "user_id": 168,
          "comment": "Clean them just to be sure",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:11:44.549Z"
        },
        {
          "id": 913,
          "area_id": 1,
          "name": "Farming and Rural Supplies",
          "created_at": "2018-07-07T11:43:50.277Z",
          "updated_at": "2018-10-10T11:05:36.801Z",
          "x": 7252,
          "y": 8321,
          "score": 0,
          "user_id": 199,
          "comment": "Seeds etc., some general hardware, plus guns in the back room.",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:05:36.797Z"
        },
        {
          "id": 914,
          "area_id": 1,
          "name": "General Store",
          "created_at": "2018-07-07T11:45:05.327Z",
          "updated_at": "2018-10-10T11:05:52.256Z",
          "x": 7296,
          "y": 8253,
          "score": 0,
          "user_id": 199,
          "comment": "Lots of food and some clothes",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:05:52.255Z"
        },
        {
          "id": 917,
          "area_id": 1,
          "name": "Doctor's Office",
          "created_at": "2018-07-07T11:46:30.036Z",
          "updated_at": "2018-10-04T09:25:05.892Z",
          "x": 7296,
          "y": 8388,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:25:05.892Z"
        },
        {
          "id": 918,
          "area_id": 1,
          "name": "Police Station",
          "created_at": "2018-07-07T11:47:16.132Z",
          "updated_at": "2018-10-10T11:05:17.571Z",
          "x": 7252,
          "y": 8378,
          "score": 10,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:05:17.571Z"
        },
        {
          "id": 919,
          "area_id": 1,
          "name": "E.P. Tools",
          "created_at": "2018-07-07T11:48:21.174Z",
          "updated_at": "2018-10-10T11:04:31.685Z",
          "x": 7256,
          "y": 8231,
          "score": 10,
          "user_id": 199,
          "comment": "Hardware shop",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:04:31.685Z"
        },
        {
          "id": 920,
          "area_id": 1,
          "name": "Burger joint",
          "created_at": "2018-07-07T11:48:39.207Z",
          "updated_at": "2018-10-04T09:24:25.768Z",
          "x": 7234,
          "y": 8203,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:24:25.768Z"
        },
        {
          "id": 922,
          "area_id": 1,
          "name": "Church",
          "created_at": "2018-07-07T11:52:23.127Z",
          "updated_at": "2018-10-04T09:24:51.003Z",
          "x": 7387,
          "y": 8353,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:24:51.003Z"
        },
        {
          "id": 923,
          "area_id": 1,
          "name": "Bookshop",
          "created_at": "2018-07-07T11:52:56.825Z",
          "updated_at": "2018-10-04T09:24:06.605Z",
          "x": 7258,
          "y": 8431,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:24:06.604Z"
        },
        {
          "id": 925,
          "area_id": 1,
          "name": "Bar",
          "created_at": "2018-07-07T11:53:37.344Z",
          "updated_at": "2018-10-04T09:24:00.581Z",
          "x": 7246,
          "y": 8522,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:24:00.581Z"
        },
        {
          "id": 927,
          "area_id": 1,
          "name": "Double warehouse",
          "created_at": "2018-07-07T11:55:26.875Z",
          "updated_at": "2018-10-04T09:25:29.182Z",
          "x": 7657,
          "y": 9343,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:25:29.181Z"
        },
        {
          "id": 928,
          "area_id": 1,
          "name": "Double small warehouses",
          "created_at": "2018-07-07T11:55:59.827Z",
          "updated_at": "2018-10-04T09:25:17.364Z",
          "x": 6560,
          "y": 8946,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:25:17.363Z"
        },
        {
          "id": 930,
          "area_id": 1,
          "name": "Double small warehouses",
          "created_at": "2018-07-07T11:56:51.919Z",
          "updated_at": "2018-10-04T09:25:16.984Z",
          "x": 5892,
          "y": 9861,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:25:16.984Z"
        },
        {
          "id": 931,
          "area_id": 1,
          "name": "Gas station",
          "created_at": "2018-07-07T11:57:17.300Z",
          "updated_at": "2018-10-10T11:05:41.513Z",
          "x": 5467,
          "y": 9709,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:05:41.513Z"
        },
        {
          "id": 933,
          "area_id": 1,
          "name": "Auto repair shop",
          "created_at": "2018-07-07T11:57:55.366Z",
          "updated_at": "2018-10-04T09:23:53.693Z",
          "x": 5470,
          "y": 9661,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:23:53.692Z"
        },
        {
          "id": 935,
          "area_id": 1,
          "name": "Farm produce shop",
          "created_at": "2018-07-07T11:59:14.383Z",
          "updated_at": "2018-10-10T11:05:28.789Z",
          "x": 5465,
          "y": 9584,
          "score": 0,
          "user_id": 199,
          "comment": "With adjoining cafés",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:05:28.789Z"
        },
        {
          "id": 936,
          "area_id": 1,
          "name": "Doctor's Office",
          "created_at": "2018-07-07T11:59:50.565Z",
          "updated_at": "2018-10-04T09:25:05.492Z",
          "x": 5499,
          "y": 9582,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:25:05.492Z"
        },
        {
          "id": 937,
          "area_id": 1,
          "name": "Clothes store",
          "created_at": "2018-07-07T12:00:08.143Z",
          "updated_at": "2018-10-04T09:24:57.603Z",
          "x": 5501,
          "y": 9566,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:24:57.603Z"
        },
        {
          "id": 939,
          "area_id": 1,
          "name": "Double warehouse",
          "created_at": "2018-07-07T12:02:06.676Z",
          "updated_at": "2018-10-04T09:25:28.792Z",
          "x": 8279,
          "y": 10039,
          "score": 0,
          "user_id": 199,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:25:28.791Z"
        },
        {
          "id": 999,
          "area_id": 1,
          "name": "Farming \u0026 Rural Supply",
          "created_at": "2018-10-07T12:01:40.449Z",
          "updated_at": "2018-10-10T11:05:08.572Z",
          "x": 7252,
          "y": 8322,
          "score": 10,
          "user_id": 215,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-10T11:05:08.572Z"
        }
      ],
      "url": "/POI/maps/1/areas/1/pois"
    },
    {
      "id": 2,
      "name": "Muldraugh",
      "pois": [
        {
          "id": 10,
          "area_id": 2,
          "name": "Abandoned cabins",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10969,
          "y": 9173,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 11,
          "area_id": 2,
          "name": "Abandoned restaurant",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10625,
          "y": 10047,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 12,
          "area_id": 2,
          "name": "Adult Education Center",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10638,
          "y": 9904,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 13,
          "area_id": 2,
          "name": "All you can eat buffet",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10619,
          "y": 9437,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 14,
          "area_id": 2,
          "name": "Baseball field",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10955,
          "y": 9944,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 15,
          "area_id": 2,
          "name": "Big urban warehouse",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10694,
          "y": 10103,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 16,
          "area_id": 2,
          "name": "Burger and Laundromat",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10606,
          "y": 9467,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 17,
          "area_id": 2,
          "name": "Cabin in the western woods",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 9664,
          "y": 8773,
          "score": 500,
          "user_id": 1,
          "comment": "Do you really want to be alone in the woods now?\nHas a well.",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 18,
          "area_id": 2,
          "name": "Car repair shop",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10605,
          "y": 9406,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 19,
          "area_id": 2,
          "name": "Chapel",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10723,
          "y": 9711,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 20,
          "area_id": 2,
          "name": "Church",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10773,
          "y": 10169,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 21,
          "area_id": 2,
          "name": "Construction Site",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10061,
          "y": 9576,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 22,
          "area_id": 2,
          "name": "Convenience Store",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10838,
          "y": 10029,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 23,
          "area_id": 2,
          "name": "Cortman Medical",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10876,
          "y": 10028,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 24,
          "area_id": 2,
          "name": "Diner",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10614,
          "y": 10559,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 25,
          "area_id": 2,
          "name": "Electronics Store",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10614,
          "y": 9881,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 26,
          "area_id": 2,
          "name": "Elementary school",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10619,
          "y": 9967,
          "score": 500,
          "user_id": 1,
          "comment": "All those fire drills finally paid off!",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 27,
          "area_id": 2,
          "name": "Farm to the west",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 8599,
          "y": 8824,
          "score": 500,
          "user_id": 1,
          "comment": "Has a tool shed, chickencoop and well.",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 28,
          "area_id": 2,
          "name": "Farmers Market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10849,
          "y": 9761,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 29,
          "area_id": 2,
          "name": "Forest house",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11063,
          "y": 10638,
          "score": 500,
          "user_id": 1,
          "comment": "Do you really want to be alone in the woods right now? Without a well?",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 30,
          "area_id": 2,
          "name": "Fossoil gas station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10624,
          "y": 9761,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 31,
          "area_id": 2,
          "name": "Garages",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10602,
          "y": 9360,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 32,
          "area_id": 2,
          "name": "Gas-2-Go",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10668,
          "y": 10625,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 33,
          "area_id": 2,
          "name": "Gated community",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10668,
          "y": 9572,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 34,
          "area_id": 2,
          "name": "Greene's Grocery",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10609,
          "y": 10259,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 35,
          "area_id": 2,
          "name": "H. Smith Attorney",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10648,
          "y": 9648,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 36,
          "area_id": 2,
          "name": "Isolated Forest House",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11600,
          "y": 9295,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 37,
          "area_id": 2,
          "name": "Isolated House",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10945,
          "y": 9370,
          "score": 500,
          "user_id": 1,
          "comment": "An isolated house with only 1 way in and out, making it popular stronghold among players because its resource efficent and zombie safe(ish) while being close to the main part of the map.",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 38,
          "area_id": 2,
          "name": "Kate and Baldspots safehouse",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10746,
          "y": 9415,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 39,
          "area_id": 2,
          "name": "Knox Bank",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10622,
          "y": 9691,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 40,
          "area_id": 2,
          "name": "Laundromat",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10914,
          "y": 9842,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 41,
          "area_id": 2,
          "name": "Lone forest shack",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 9341,
          "y": 10293,
          "score": 500,
          "user_id": 1,
          "comment": "Do you really want to be alone in the woods right now? Without a well?",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 42,
          "area_id": 2,
          "name": "Lumber Mill",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10319,
          "y": 9595,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 43,
          "area_id": 2,
          "name": "Mini mall",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10606,
          "y": 10358,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 44,
          "area_id": 2,
          "name": "Motel",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10900,
          "y": 9749,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 45,
          "area_id": 2,
          "name": "North Farm",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10815,
          "y": 9105,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 46,
          "area_id": 2,
          "name": "Offices",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10673,
          "y": 10327,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 47,
          "area_id": 2,
          "name": "Picnic area",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10992,
          "y": 9645,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 48,
          "area_id": 2,
          "name": "Pile-o-Crepe",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10613,
          "y": 9505,
          "score": 500,
          "user_id": 1,
          "comment": "Pancake house",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 49,
          "area_id": 2,
          "name": "Pizza Whirled",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10604,
          "y": 10111,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 50,
          "area_id": 2,
          "name": "Police station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10631,
          "y": 10405,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 51,
          "area_id": 2,
          "name": "Pony Roam-O",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 8559,
          "y": 8529,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 52,
          "area_id": 2,
          "name": "Railyard",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11655,
          "y": 9985,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 53,
          "area_id": 2,
          "name": "Restaurant",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10619,
          "y": 10528,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 54,
          "area_id": 2,
          "name": "Self-Storage buildings",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10684,
          "y": 9829,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 55,
          "area_id": 2,
          "name": "Small convenience store",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10836,
          "y": 9530,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 56,
          "area_id": 2,
          "name": "Small storage facility",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10752,
          "y": 10347,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 57,
          "area_id": 2,
          "name": "Small warehouse",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10712,
          "y": 10144,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 58,
          "area_id": 2,
          "name": "Spiffo's",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10621,
          "y": 9650,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 59,
          "area_id": 2,
          "name": "Storage building",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10627,
          "y": 9378,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 60,
          "area_id": 2,
          "name": "Sunstar Hotel",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10628,
          "y": 9815,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 61,
          "area_id": 2,
          "name": "Tattoo 42",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10614,
          "y": 10155,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 62,
          "area_id": 2,
          "name": "Tavern",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10758,
          "y": 10547,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 63,
          "area_id": 2,
          "name": "The McCoy Logging Corp.",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10317,
          "y": 9290,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 64,
          "area_id": 2,
          "name": "The large warehouse",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10611,
          "y": 9308,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 65,
          "area_id": 2,
          "name": "Train station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11096,
          "y": 9237,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 66,
          "area_id": 2,
          "name": "Warehouses out of town",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10050,
          "y": 10932,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 67,
          "area_id": 2,
          "name": "Warehouses",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10713,
          "y": 10443,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 68,
          "area_id": 2,
          "name": "Zippee Market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10604,
          "y": 9613,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 243,
          "area_id": 2,
          "name": "Cafeteria",
          "created_at": "2015-04-10T12:31:15.000Z",
          "updated_at": "2015-06-17T07:21:59.000Z",
          "x": 10648,
          "y": 9924,
          "score": 27,
          "user_id": 28,
          "comment": "Free WIFI? Apparently not.",
          "approver_id": 2,
          "approved_at": "2015-06-17T07:21:59.000Z"
        },
        {
          "id": 245,
          "area_id": 2,
          "name": "Soccer Field",
          "created_at": "2015-04-10T12:34:03.000Z",
          "updated_at": "2015-06-17T07:22:01.000Z",
          "x": 10656,
          "y": 9970,
          "score": 27,
          "user_id": 28,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-17T07:22:01.000Z"
        },
        {
          "id": 647,
          "area_id": 2,
          "name": "Camp Grounds",
          "created_at": "2017-05-31T00:38:37.012Z",
          "updated_at": "2017-07-27T12:18:09.446Z",
          "x": 12422,
          "y": 8927,
          "score": 19,
          "user_id": 131,
          "comment": "A Group of Cabins In The Woods Near Muldraugh",
          "approver_id": 2,
          "approved_at": "2017-07-27T12:18:09.440Z"
        },
        {
          "id": 871,
          "area_id": 2,
          "name": "Bookshop",
          "created_at": "2018-01-14T19:26:53.923Z",
          "updated_at": "2018-05-11T11:11:50.776Z",
          "x": 10610,
          "y": 10365,
          "score": 20,
          "user_id": 170,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:11:50.776Z"
        },
        {
          "id": 872,
          "area_id": 2,
          "name": "Cross roads",
          "created_at": "2018-02-08T19:37:18.195Z",
          "updated_at": "2018-05-11T11:12:08.958Z",
          "x": 10591,
          "y": 11204,
          "score": 20,
          "user_id": 171,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:12:08.955Z"
        }
      ],
      "url": "/POI/maps/1/areas/2/pois"
    },
    {
      "id": 3,
      "name": "Valley Station",
      "pois": [
        {
          "id": 69,
          "area_id": 3,
          "name": "Academy",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12857,
          "y": 4856,
          "score": 500,
          "user_id": 1,
          "comment": "Submitted by Seku",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 70,
          "area_id": 3,
          "name": "Bridge",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12309,
          "y": 6730,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 71,
          "area_id": 3,
          "name": "Burger shop",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12544,
          "y": 5266,
          "score": 500,
          "user_id": 1,
          "comment": "100% fresh meat",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 72,
          "area_id": 3,
          "name": "Church",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12848,
          "y": 4956,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 73,
          "area_id": 3,
          "name": "Construction site #2",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 14123,
          "y": 5493,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 74,
          "area_id": 3,
          "name": "Construction site",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12582,
          "y": 5387,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 75,
          "area_id": 3,
          "name": "Destroyed cabin",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12531,
          "y": 5232,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 76,
          "area_id": 3,
          "name": "Diner",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12741,
          "y": 5711,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 77,
          "area_id": 3,
          "name": "Food Market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12571,
          "y": 5728,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 78,
          "area_id": 3,
          "name": "Gas Station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12734,
          "y": 5033,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 79,
          "area_id": 3,
          "name": "Gas-2-Go",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13734,
          "y": 5643,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 80,
          "area_id": 3,
          "name": "Hunting Cabin",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13651,
          "y": 7145,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 81,
          "area_id": 3,
          "name": "Hunting Lodge",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13105,
          "y": 5315,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 82,
          "area_id": 3,
          "name": "Hunting stand",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13403,
          "y": 5338,
          "score": 500,
          "user_id": 1,
          "comment": "Watch out for bears",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 83,
          "area_id": 3,
          "name": "Knox Bank",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13656,
          "y": 5745,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 84,
          "area_id": 3,
          "name": "Pile-o-Crepe",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13602,
          "y": 5762,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 85,
          "area_id": 3,
          "name": "Racetrack / Abandoned airfield",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12840,
          "y": 6402,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 86,
          "area_id": 3,
          "name": "Shooting Range",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13266,
          "y": 5442,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 87,
          "area_id": 3,
          "name": "Small Hotel",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13008,
          "y": 5321,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 88,
          "area_id": 3,
          "name": "Small dock",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12482,
          "y": 5298,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 89,
          "area_id": 3,
          "name": "Small shop",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12627,
          "y": 5324,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 90,
          "area_id": 3,
          "name": "StarEplex Cinema",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13629,
          "y": 5881,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 91,
          "area_id": 3,
          "name": "The Mall",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13930,
          "y": 5878,
          "score": 500,
          "user_id": 1,
          "comment": "Beware the Shopping Dead",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 92,
          "area_id": 3,
          "name": "Train station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12605,
          "y": 6004,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 93,
          "area_id": 3,
          "name": "Zippee market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 13658,
          "y": 5766,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 212,
          "area_id": 3,
          "name": "Yummmers",
          "created_at": "2015-03-04T08:57:44.000Z",
          "updated_at": "2015-11-19T07:27:26.000Z",
          "x": 13571,
          "y": 5761,
          "score": 11,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:26.000Z"
        },
        {
          "id": 213,
          "area_id": 3,
          "name": "Kindergarten",
          "created_at": "2015-03-04T08:58:41.000Z",
          "updated_at": "2015-11-19T07:27:27.000Z",
          "x": 13585,
          "y": 5669,
          "score": 11,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:27.000Z"
        },
        {
          "id": 214,
          "area_id": 3,
          "name": "Beauty Salon",
          "created_at": "2015-03-04T09:02:27.000Z",
          "updated_at": "2015-03-04T14:12:31.000Z",
          "x": 13619,
          "y": 5672,
          "score": 0,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-03-04T14:12:31.000Z"
        },
        {
          "id": 215,
          "area_id": 3,
          "name": "Clothing Shop",
          "created_at": "2015-03-04T09:05:52.000Z",
          "updated_at": "2015-11-19T07:27:29.000Z",
          "x": 13686,
          "y": 5672,
          "score": 11,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:29.000Z"
        },
        {
          "id": 216,
          "area_id": 3,
          "name": "Insurance Office",
          "created_at": "2015-03-04T09:06:05.000Z",
          "updated_at": "2015-11-19T07:27:28.000Z",
          "x": 13665,
          "y": 5671,
          "score": 11,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:28.000Z"
        },
        {
          "id": 217,
          "area_id": 3,
          "name": "Restaurant",
          "created_at": "2015-03-04T09:06:17.000Z",
          "updated_at": "2015-11-19T07:27:27.000Z",
          "x": 13649,
          "y": 5671,
          "score": 11,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:27.000Z"
        },
        {
          "id": 224,
          "area_id": 3,
          "name": "Book Store",
          "created_at": "2015-03-13T20:28:51.000Z",
          "updated_at": "2015-06-17T07:22:52.000Z",
          "x": 13604,
          "y": 5668,
          "score": 21,
          "user_id": 21,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-17T07:22:52.000Z"
        },
        {
          "id": 225,
          "area_id": 3,
          "name": "Beauty Salon",
          "created_at": "2015-03-13T20:29:14.000Z",
          "updated_at": "2015-06-17T07:22:52.000Z",
          "x": 13620,
          "y": 5668,
          "score": 21,
          "user_id": 21,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-17T07:22:52.000Z"
        },
        {
          "id": 315,
          "area_id": 3,
          "name": "House near the lake",
          "created_at": "2015-06-05T20:06:51.000Z",
          "updated_at": "2015-06-19T12:46:29.000Z",
          "x": 14059,
          "y": 5199,
          "score": 10,
          "user_id": 41,
          "comment": "With tool shed and small lake go fishing",
          "approver_id": 2,
          "approved_at": "2015-06-19T12:46:29.000Z"
        },
        {
          "id": 316,
          "area_id": 3,
          "name": "Small Farm",
          "created_at": "2015-06-05T20:10:35.000Z",
          "updated_at": "2015-06-19T12:45:59.000Z",
          "x": 14315,
          "y": 4953,
          "score": 27,
          "user_id": 41,
          "comment": "With big garden, small tool shed and food stand ",
          "approver_id": 2,
          "approved_at": "2015-06-19T12:45:59.000Z"
        },
        {
          "id": 317,
          "area_id": 3,
          "name": "Church",
          "created_at": "2015-06-05T20:12:17.000Z",
          "updated_at": "2015-11-19T07:27:31.000Z",
          "x": 14551,
          "y": 4970,
          "score": 17,
          "user_id": 41,
          "comment": "Small Church with small tool shed near",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:31.000Z"
        },
        {
          "id": 321,
          "area_id": 3,
          "name": "Medium Warehouse",
          "created_at": "2015-06-05T20:16:12.000Z",
          "updated_at": "2015-06-19T12:48:31.000Z",
          "x": 12620,
          "y": 4713,
          "score": 27,
          "user_id": 41,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-19T12:48:31.000Z"
        },
        {
          "id": 329,
          "area_id": 3,
          "name": "Yummmess bar",
          "created_at": "2015-06-09T22:58:53.000Z",
          "updated_at": "2015-11-19T07:27:29.000Z",
          "x": 13571,
          "y": 5762,
          "score": 17,
          "user_id": 46,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:29.000Z"
        },
        {
          "id": 336,
          "area_id": 3,
          "name": "Graveyard",
          "created_at": "2015-07-14T02:22:05.000Z",
          "updated_at": "2015-11-19T07:27:30.000Z",
          "x": 14556,
          "y": 4972,
          "score": 17,
          "user_id": 52,
          "comment": "Really? this is the ZOMBIE APOCALYPSE and your gonna hide in the place with the dead people?",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:30.000Z"
        },
        {
          "id": 368,
          "area_id": 3,
          "name": "Bus Station",
          "created_at": "2015-10-04T10:27:23.000Z",
          "updated_at": "2015-11-19T07:27:32.000Z",
          "x": 12733,
          "y": 5773,
          "score": 29,
          "user_id": 63,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:32.000Z"
        },
        {
          "id": 470,
          "area_id": 3,
          "name": "East Bridge",
          "created_at": "2015-12-31T01:06:10.000Z",
          "updated_at": "2016-02-27T09:30:48.000Z",
          "x": 12895,
          "y": 6898,
          "score": 10,
          "user_id": 78,
          "comment": "Bridge Connecting Valley Station and Westpoint",
          "approver_id": 2,
          "approved_at": "2016-02-27T09:30:48.000Z"
        },
        {
          "id": 895,
          "area_id": 3,
          "name": "Big Farm",
          "created_at": "2018-06-24T12:35:32.469Z",
          "updated_at": "2018-10-04T09:26:12.739Z",
          "x": 13856,
          "y": 4740,
          "score": 0,
          "user_id": 192,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-10-04T09:26:12.738Z"
        }
      ],
      "url": "/POI/maps/1/areas/3/pois"
    },
    {
      "id": 4,
      "name": "West Point",
      "pois": [
        {
          "id": 94,
          "area_id": 4,
          "name": "Auto shop",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11898,
          "y": 6807,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 95,
          "area_id": 4,
          "name": "Burgers",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12077,
          "y": 7075,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 96,
          "area_id": 4,
          "name": "Church and cemetary",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11069,
          "y": 6710,
          "score": 500,
          "user_id": 1,
          "comment": "Does anyone else think it's a bad idea being on a cemetary during the zombie apocalypse?",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 97,
          "area_id": 4,
          "name": "Church",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11967,
          "y": 6990,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 98,
          "area_id": 4,
          "name": "Crate shed",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10942,
          "y": 6769,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 99,
          "area_id": 4,
          "name": "Dock",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11828,
          "y": 6574,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 100,
          "area_id": 4,
          "name": "Elementary School",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11740,
          "y": 6923,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 101,
          "area_id": 4,
          "name": "Enigma books and Mendy's eatery",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11896,
          "y": 6880,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 102,
          "area_id": 4,
          "name": "Farmhouse",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11132,
          "y": 6852,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 103,
          "area_id": 4,
          "name": "Food Market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11978,
          "y": 6911,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 104,
          "area_id": 4,
          "name": "Fossoil",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12069,
          "y": 7140,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 105,
          "area_id": 4,
          "name": "GIGA Mart",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12024,
          "y": 6853,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 106,
          "area_id": 4,
          "name": "Gun Shop",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12064,
          "y": 6758,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 107,
          "area_id": 4,
          "name": "Hair-o-Genesis",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11852,
          "y": 6878,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 108,
          "area_id": 4,
          "name": "Hardware store and Food market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11961,
          "y": 6910,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 109,
          "area_id": 4,
          "name": "House by the lake",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 10182,
          "y": 6763,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 110,
          "area_id": 4,
          "name": "Knox Bank",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11913,
          "y": 6914,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 111,
          "area_id": 4,
          "name": "Large Warehouse",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12140,
          "y": 7084,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 112,
          "area_id": 4,
          "name": "Laundromat",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11931,
          "y": 6786,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 113,
          "area_id": 4,
          "name": "Mini Hotel",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12018,
          "y": 6917,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 114,
          "area_id": 4,
          "name": "Motel",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11860,
          "y": 6906,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 115,
          "area_id": 4,
          "name": "Pharmahug",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11935,
          "y": 6802,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 116,
          "area_id": 4,
          "name": "Picnic area",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12033,
          "y": 7364,
          "score": 500,
          "user_id": 1,
          "comment": "Consumption of brought food and drinks strictly forbidden!",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 117,
          "area_id": 4,
          "name": "Pizza Whirled",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11660,
          "y": 7083,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 118,
          "area_id": 4,
          "name": "Playground",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11418,
          "y": 6782,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 119,
          "area_id": 4,
          "name": "Police Station",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11898,
          "y": 6940,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 120,
          "area_id": 4,
          "name": "Post office",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11956,
          "y": 6912,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 121,
          "area_id": 4,
          "name": "R.B. Mat, Dentist",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11880,
          "y": 6879,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 122,
          "area_id": 4,
          "name": "School",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11345,
          "y": 6784,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 123,
          "area_id": 4,
          "name": "Seahorse Coffee",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11962,
          "y": 6879,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 124,
          "area_id": 4,
          "name": "Spiffo's",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11976,
          "y": 6810,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 125,
          "area_id": 4,
          "name": "Storage Lots",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12137,
          "y": 7024,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 126,
          "area_id": 4,
          "name": "Store constuction",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12062,
          "y": 6918,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 127,
          "area_id": 4,
          "name": "Sweets shop",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11349,
          "y": 6719,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 128,
          "area_id": 4,
          "name": "The Drake and Crystal Pie Place",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11899,
          "y": 6851,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 129,
          "area_id": 4,
          "name": "Thunder Gas",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11820,
          "y": 6865,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 130,
          "area_id": 4,
          "name": "Town Hall",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11941,
          "y": 6873,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 131,
          "area_id": 4,
          "name": "Twiggy's",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 12059,
          "y": 6793,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 132,
          "area_id": 4,
          "name": "Zippee Market",
          "created_at": "2015-02-01T18:00:14.000Z",
          "updated_at": "2015-02-01T18:00:14.000Z",
          "x": 11660,
          "y": 7067,
          "score": 500,
          "user_id": 1,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-02-01T18:00:14.000Z"
        },
        {
          "id": 206,
          "area_id": 4,
          "name": "Furniture Shop",
          "created_at": "2015-03-04T08:41:59.000Z",
          "updated_at": "2015-07-16T06:54:51.000Z",
          "x": 11932,
          "y": 6941,
          "score": 21,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-07-16T06:54:51.000Z"
        },
        {
          "id": 207,
          "area_id": 4,
          "name": "Restaurant",
          "created_at": "2015-03-04T08:42:52.000Z",
          "updated_at": "2015-07-16T06:54:48.000Z",
          "x": 11932,
          "y": 6914,
          "score": 21,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-07-16T06:54:48.000Z"
        },
        {
          "id": 208,
          "area_id": 4,
          "name": "Office Building",
          "created_at": "2015-03-04T08:43:39.000Z",
          "updated_at": "2015-07-16T06:54:50.000Z",
          "x": 11976,
          "y": 6939,
          "score": 21,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-07-16T06:54:50.000Z"
        },
        {
          "id": 209,
          "area_id": 4,
          "name": "Office Building #2",
          "created_at": "2015-03-04T08:46:16.000Z",
          "updated_at": "2015-07-16T06:54:49.000Z",
          "x": 11977,
          "y": 6882,
          "score": 21,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-07-16T06:54:49.000Z"
        },
        {
          "id": 210,
          "area_id": 4,
          "name": "Commercial Rent",
          "created_at": "2015-03-04T08:53:06.000Z",
          "updated_at": "2015-06-17T07:23:02.000Z",
          "x": 11666,
          "y": 7036,
          "score": 21,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-17T07:23:02.000Z"
        },
        {
          "id": 211,
          "area_id": 4,
          "name": "Dock",
          "created_at": "2015-03-04T08:54:09.000Z",
          "updated_at": "2015-07-16T06:54:51.000Z",
          "x": 11276,
          "y": 6594,
          "score": 21,
          "user_id": 16,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-07-16T06:54:51.000Z"
        },
        {
          "id": 223,
          "area_id": 4,
          "name": "Preschool",
          "created_at": "2015-03-13T20:27:45.000Z",
          "updated_at": "2015-06-17T07:23:01.000Z",
          "x": 13586,
          "y": 5669,
          "score": 21,
          "user_id": 21,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-17T07:23:01.000Z"
        },
        {
          "id": 318,
          "area_id": 4,
          "name": "Food Market",
          "created_at": "2015-06-05T20:14:15.000Z",
          "updated_at": "2015-06-19T12:48:37.000Z",
          "x": 13937,
          "y": 4864,
          "score": 27,
          "user_id": 41,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-19T12:48:37.000Z"
        },
        {
          "id": 319,
          "area_id": 4,
          "name": "Burger Restaurant",
          "created_at": "2015-06-05T20:14:43.000Z",
          "updated_at": "2015-11-19T07:27:36.000Z",
          "x": 13965,
          "y": 4866,
          "score": 17,
          "user_id": 41,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:36.000Z"
        },
        {
          "id": 320,
          "area_id": 4,
          "name": "Cafe",
          "created_at": "2015-06-05T20:15:36.000Z",
          "updated_at": "2015-06-19T12:48:35.000Z",
          "x": 13092,
          "y": 5449,
          "score": 27,
          "user_id": 41,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2015-06-19T12:48:35.000Z"
        },
        {
          "id": 322,
          "area_id": 4,
          "name": "Cabin",
          "created_at": "2015-06-05T20:20:36.000Z",
          "updated_at": "2015-11-19T07:27:37.000Z",
          "x": 13628,
          "y": 7222,
          "score": 27,
          "user_id": 41,
          "comment": "Do you really want to be alone in the woods without a well?",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:37.000Z"
        },
        {
          "id": 358,
          "area_id": 4,
          "name": "Valu In$urance",
          "created_at": "2015-09-12T17:27:13.000Z",
          "updated_at": "2015-11-19T07:27:36.000Z",
          "x": 13664,
          "y": 5669,
          "score": 19,
          "user_id": 60,
          "comment": "\"Sorry, zombie apocalypses aren't covered under the Act of God clause in your policy.\"",
          "approver_id": 2,
          "approved_at": "2015-11-19T07:27:36.000Z"
        },
        {
          "id": 471,
          "area_id": 4,
          "name": "Town Park",
          "created_at": "2015-12-31T01:06:59.000Z",
          "updated_at": "2016-10-31T12:29:08.655Z",
          "x": 11786,
          "y": 6913,
          "score": 19,
          "user_id": 78,
          "comment": "A nice small, suburban park.",
          "approver_id": 2,
          "approved_at": "2016-10-31T12:29:08.655Z"
        },
        {
          "id": 559,
          "area_id": 4,
          "name": "Hardware Store",
          "created_at": "2017-02-20T22:30:38.431Z",
          "updated_at": "2017-03-05T17:56:01.955Z",
          "x": 11831,
          "y": 6912,
          "score": 0,
          "user_id": 110,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:56:01.955Z"
        },
        {
          "id": 675,
          "area_id": 4,
          "name": "Police station",
          "created_at": "2017-07-01T09:09:57.217Z",
          "updated_at": "2017-10-09T08:54:20.959Z",
          "x": 8063,
          "y": 11735,
          "score": 20,
          "user_id": 135,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-10-09T08:54:20.959Z"
        },
        {
          "id": 863,
          "area_id": 4,
          "name": "Farmhouse",
          "created_at": "2017-11-19T14:18:17.736Z",
          "updated_at": "2017-11-28T08:35:10.371Z",
          "x": 14396,
          "y": 4568,
          "score": 20,
          "user_id": 163,
          "comment": "Large farmhouse with toolsheds and chicken coop",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:35:10.370Z"
        }
      ],
      "url": "/POI/maps/1/areas/4/pois"
    },
    {
      "id": 18,
      "name": "Rosewood",
      "pois": [
        {
          "id": 539,
          "area_id": 18,
          "name": "Gas station",
          "created_at": "2017-02-05T16:16:16.503Z",
          "updated_at": "2017-03-05T17:59:03.145Z",
          "x": 8297,
          "y": 12225,
          "score": 0,
          "user_id": 104,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:59:03.144Z"
        },
        {
          "id": 540,
          "area_id": 18,
          "name": "Knox prison",
          "created_at": "2017-02-06T16:38:37.230Z",
          "updated_at": "2017-03-05T17:59:10.308Z",
          "x": 7693,
          "y": 11873,
          "score": 0,
          "user_id": 104,
          "comment": "Not the best/worst place to live!",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:58:56.218Z"
        },
        {
          "id": 542,
          "area_id": 18,
          "name": "Spiffo's",
          "created_at": "2017-02-07T18:27:48.710Z",
          "updated_at": "2017-03-05T17:59:30.107Z",
          "x": 8074,
          "y": 11343,
          "score": 0,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:59:30.107Z"
        },
        {
          "id": 543,
          "area_id": 18,
          "name": "Motel",
          "created_at": "2017-02-07T18:33:32.035Z",
          "updated_at": "2017-03-05T17:58:55.905Z",
          "x": 8078,
          "y": 11417,
          "score": 0,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:58:55.903Z"
        },
        {
          "id": 545,
          "area_id": 18,
          "name": "Construction Site",
          "created_at": "2017-02-07T18:37:00.443Z",
          "updated_at": "2017-03-05T17:57:28.803Z",
          "x": 8219,
          "y": 11841,
          "score": 0,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:57:28.803Z"
        },
        {
          "id": 547,
          "area_id": 18,
          "name": "Zippee Market",
          "created_at": "2017-02-07T18:40:09.439Z",
          "updated_at": "2017-03-05T17:59:29.759Z",
          "x": 8089,
          "y": 11561,
          "score": 0,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:59:29.758Z"
        },
        {
          "id": 551,
          "area_id": 18,
          "name": "Gas Station",
          "created_at": "2017-02-07T18:45:26.149Z",
          "updated_at": "2017-03-05T17:58:32.732Z",
          "x": 8164,
          "y": 11267,
          "score": 0,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:58:32.732Z"
        },
        {
          "id": 552,
          "area_id": 18,
          "name": "Church",
          "created_at": "2017-02-07T18:46:06.386Z",
          "updated_at": "2017-03-05T17:56:55.753Z",
          "x": 8124,
          "y": 11550,
          "score": 19,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:56:55.752Z"
        },
        {
          "id": 553,
          "area_id": 18,
          "name": "Auto Shop",
          "created_at": "2017-02-08T04:17:10.449Z",
          "updated_at": "2017-03-05T17:57:18.549Z",
          "x": 8154,
          "y": 11325,
          "score": 19,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:57:18.549Z"
        },
        {
          "id": 554,
          "area_id": 18,
          "name": "Rosewood Medical",
          "created_at": "2017-02-08T04:28:49.560Z",
          "updated_at": "2017-03-05T17:59:30.359Z",
          "x": 8089,
          "y": 11526,
          "score": 0,
          "user_id": 105,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:59:30.358Z"
        },
        {
          "id": 558,
          "area_id": 18,
          "name": "Knox County Court of Justice",
          "created_at": "2017-02-13T01:34:34.677Z",
          "updated_at": "2017-03-05T17:59:02.830Z",
          "x": 8075,
          "y": 11654,
          "score": 0,
          "user_id": 109,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T17:59:02.830Z"
        },
        {
          "id": 561,
          "area_id": 18,
          "name": "Elementary School",
          "created_at": "2017-03-04T12:05:45.185Z",
          "updated_at": "2017-03-05T18:03:13.160Z",
          "x": 8341,
          "y": 11612,
          "score": 0,
          "user_id": 112,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-03-05T18:03:13.160Z"
        },
        {
          "id": 575,
          "area_id": 18,
          "name": "Fresh Food Market",
          "created_at": "2017-03-31T19:20:36.898Z",
          "updated_at": "2017-11-02T08:57:59.447Z",
          "x": 9074,
          "y": 12186,
          "score": 0,
          "user_id": 116,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:57:59.445Z"
        },
        {
          "id": 589,
          "area_id": 18,
          "name": "Bar",
          "created_at": "2017-04-19T03:43:59.645Z",
          "updated_at": "2017-05-23T09:19:38.625Z",
          "x": 8019,
          "y": 11427,
          "score": 0,
          "user_id": 120,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-05-23T09:19:38.624Z"
        },
        {
          "id": 594,
          "area_id": 18,
          "name": "Clothing Store",
          "created_at": "2017-04-19T03:48:03.300Z",
          "updated_at": "2017-11-02T08:34:59.639Z",
          "x": 8087,
          "y": 11489,
          "score": 0,
          "user_id": 120,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:34:59.638Z"
        },
        {
          "id": 595,
          "area_id": 18,
          "name": "All-you-can-eat Buffet",
          "created_at": "2017-04-19T03:48:47.325Z",
          "updated_at": "2017-05-23T09:16:36.343Z",
          "x": 8086,
          "y": 11500,
          "score": 19,
          "user_id": 120,
          "comment": "*Disclaimer: Not all-you-can-eat.",
          "approver_id": 2,
          "approved_at": "2017-05-23T09:16:36.341Z"
        },
        {
          "id": 596,
          "area_id": 18,
          "name": "Bookstore",
          "created_at": "2017-04-19T03:48:59.045Z",
          "updated_at": "2017-05-23T09:19:46.884Z",
          "x": 8087,
          "y": 11512,
          "score": 0,
          "user_id": 120,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-05-23T09:19:46.883Z"
        },
        {
          "id": 598,
          "area_id": 18,
          "name": "Law Offices",
          "created_at": "2017-04-19T03:50:12.468Z",
          "updated_at": "2017-11-07T19:27:48.155Z",
          "x": 8089,
          "y": 11536,
          "score": 20,
          "user_id": 120,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:27:46.260Z"
        },
        {
          "id": 604,
          "area_id": 18,
          "name": "Mama McFudgeington's",
          "created_at": "2017-04-19T03:53:41.619Z",
          "updated_at": "2017-11-07T19:28:22.909Z",
          "x": 8085,
          "y": 11606,
          "score": 20,
          "user_id": 120,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:28:22.156Z"
        },
        {
          "id": 632,
          "area_id": 18,
          "name": "Gated Community",
          "created_at": "2017-05-03T04:33:00.504Z",
          "updated_at": "2017-11-07T19:25:14.803Z",
          "x": 8026,
          "y": 11525,
          "score": 20,
          "user_id": 126,
          "comment": "Good for base defense as zombies can't break through the fences.",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:25:14.801Z"
        },
        {
          "id": 643,
          "area_id": 18,
          "name": "Car Repair",
          "created_at": "2017-05-24T13:52:47.556Z",
          "updated_at": "2017-11-02T08:18:24.977Z",
          "x": 8154,
          "y": 11327,
          "score": 40,
          "user_id": 129,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:18:24.977Z"
        },
        {
          "id": 649,
          "area_id": 18,
          "name": "House",
          "created_at": "2017-06-09T05:33:17.917Z",
          "updated_at": "2017-11-07T19:26:54.074Z",
          "x": 8341,
          "y": 11750,
          "score": 20,
          "user_id": 132,
          "comment": "Single level house with farm plots, a tool shed and a high fence around with one way in. Good for a possible base location. ",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:26:52.250Z"
        },
        {
          "id": 662,
          "area_id": 18,
          "name": "Buffet",
          "created_at": "2017-06-09T05:54:29.891Z",
          "updated_at": "2017-11-02T08:17:18.656Z",
          "x": 8087,
          "y": 11501,
          "score": 30,
          "user_id": 132,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:17:18.655Z"
        },
        {
          "id": 663,
          "area_id": 18,
          "name": "Diner",
          "created_at": "2017-06-09T05:55:07.414Z",
          "updated_at": "2017-11-02T08:44:24.358Z",
          "x": 8075,
          "y": 11456,
          "score": 0,
          "user_id": 132,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:44:24.355Z"
        },
        {
          "id": 668,
          "area_id": 18,
          "name": "Bail Bonds",
          "created_at": "2017-06-09T06:06:12.183Z",
          "updated_at": "2017-11-02T08:17:18.213Z",
          "x": 7994,
          "y": 11450,
          "score": 30,
          "user_id": 132,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:17:18.210Z"
        },
        {
          "id": 677,
          "area_id": 18,
          "name": "Court house",
          "created_at": "2017-07-01T09:10:35.794Z",
          "updated_at": "2017-11-02T08:42:48.701Z",
          "x": 8071,
          "y": 11660,
          "score": 0,
          "user_id": 135,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:42:48.699Z"
        },
        {
          "id": 681,
          "area_id": 18,
          "name": "Rosewood Penitentiary",
          "created_at": "2017-07-12T13:26:57.997Z",
          "updated_at": "2017-11-02T08:18:23.887Z",
          "x": 7752,
          "y": 11886,
          "score": 40,
          "user_id": 137,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:18:23.887Z"
        },
        {
          "id": 685,
          "area_id": 18,
          "name": "Fossoil",
          "created_at": "2017-07-17T11:42:26.443Z",
          "updated_at": "2017-11-02T08:57:30.390Z",
          "x": 8283,
          "y": 12210,
          "score": 0,
          "user_id": 139,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:57:30.389Z"
        },
        {
          "id": 722,
          "area_id": 18,
          "name": "Salon",
          "created_at": "2017-07-18T05:40:10.155Z",
          "updated_at": "2017-11-07T19:32:35.444Z",
          "x": 8096,
          "y": 11552,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:32:35.444Z"
        },
        {
          "id": 723,
          "area_id": 18,
          "name": "Apartment Complex",
          "created_at": "2017-07-18T05:40:49.791Z",
          "updated_at": "2017-11-02T08:18:23.324Z",
          "x": 8081,
          "y": 11535,
          "score": 20,
          "user_id": 141,
          "comment": "Top floor of this building contains apartments available for looting",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:18:23.323Z"
        },
        {
          "id": 725,
          "area_id": 18,
          "name": "Bank",
          "created_at": "2017-07-18T05:47:48.880Z",
          "updated_at": "2017-11-02T08:25:13.194Z",
          "x": 8096,
          "y": 11594,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:25:13.193Z"
        },
        {
          "id": 734,
          "area_id": 18,
          "name": "Bus Station",
          "created_at": "2017-07-18T07:03:35.446Z",
          "updated_at": "2017-11-02T08:25:42.153Z",
          "x": 8244,
          "y": 12239,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:25:42.150Z"
        },
        {
          "id": 736,
          "area_id": 18,
          "name": "Farmer's Market",
          "created_at": "2017-07-18T07:35:55.124Z",
          "updated_at": "2017-11-02T08:44:39.163Z",
          "x": 9070,
          "y": 12200,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:44:39.161Z"
        },
        {
          "id": 737,
          "area_id": 18,
          "name": "Packaging Warehouses",
          "created_at": "2017-07-18T07:36:39.298Z",
          "updated_at": "2017-11-07T19:29:30.793Z",
          "x": 9199,
          "y": 11853,
          "score": 20,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:29:29.478Z"
        },
        {
          "id": 819,
          "area_id": 18,
          "name": "Food Market and Laundromat",
          "created_at": "2017-10-14T03:48:43.902Z",
          "updated_at": "2017-11-02T08:57:16.635Z",
          "x": 8136,
          "y": 11485,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:57:16.631Z"
        },
        {
          "id": 829,
          "area_id": 18,
          "name": "Warehouse",
          "created_at": "2017-10-14T04:05:17.680Z",
          "updated_at": "2017-11-07T19:34:18.212Z",
          "x": 9202,
          "y": 11840,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:34:18.211Z"
        },
        {
          "id": 830,
          "area_id": 18,
          "name": "Army Quarter",
          "created_at": "2017-10-14T04:23:32.715Z",
          "updated_at": "2017-11-02T08:20:04.460Z",
          "x": 9118,
          "y": 11814,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:20:04.460Z"
        },
        {
          "id": 835,
          "area_id": 18,
          "name": "Fire Departament",
          "created_at": "2017-10-25T04:31:01.305Z",
          "updated_at": "2017-11-02T08:56:03.006Z",
          "x": 8138,
          "y": 11741,
          "score": 0,
          "user_id": 158,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-02T08:56:03.006Z"
        },
        {
          "id": 865,
          "area_id": 18,
          "name": "Pizza Restaurant",
          "created_at": "2017-12-19T02:43:06.875Z",
          "updated_at": "2018-05-11T11:12:17.675Z",
          "x": 8075,
          "y": 11310,
          "score": 0,
          "user_id": 167,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:12:17.674Z"
        }
      ],
      "url": "/POI/maps/1/areas/18/pois"
    },
    {
      "id": 19,
      "name": "March Ridge",
      "pois": [
        {
          "id": 693,
          "area_id": 19,
          "name": "Church",
          "created_at": "2017-07-17T14:27:44.128Z",
          "updated_at": "2017-11-07T19:43:09.390Z",
          "x": 10328,
          "y": 12794,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:43:09.389Z"
        },
        {
          "id": 710,
          "area_id": 19,
          "name": "Cinema",
          "created_at": "2017-07-17T14:30:05.837Z",
          "updated_at": "2017-11-07T19:43:37.247Z",
          "x": 10172,
          "y": 12645,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:43:37.247Z"
        },
        {
          "id": 711,
          "area_id": 19,
          "name": "Post Office",
          "created_at": "2017-07-17T14:30:41.421Z",
          "updated_at": "2017-11-07T19:46:16.576Z",
          "x": 10107,
          "y": 12713,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:46:16.576Z"
        },
        {
          "id": 712,
          "area_id": 19,
          "name": "Community Center",
          "created_at": "2017-07-17T14:31:20.533Z",
          "updated_at": "2017-11-07T19:43:45.873Z",
          "x": 10030,
          "y": 12732,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:43:45.872Z"
        },
        {
          "id": 713,
          "area_id": 19,
          "name": "School",
          "created_at": "2017-07-17T14:31:53.056Z",
          "updated_at": "2017-11-07T19:46:37.484Z",
          "x": 9999,
          "y": 12655,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:46:37.483Z"
        },
        {
          "id": 714,
          "area_id": 19,
          "name": "Food Market",
          "created_at": "2017-07-17T14:35:00.533Z",
          "updated_at": "2017-11-07T19:44:17.032Z",
          "x": 10113,
          "y": 12786,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:44:17.032Z"
        },
        {
          "id": 715,
          "area_id": 19,
          "name": "Gas Station",
          "created_at": "2017-07-17T14:35:34.231Z",
          "updated_at": "2017-11-07T19:44:25.648Z",
          "x": 10144,
          "y": 12791,
          "score": 0,
          "user_id": 140,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:44:25.647Z"
        },
        {
          "id": 743,
          "area_id": 19,
          "name": "Mormix McFudgington's",
          "created_at": "2017-07-18T07:52:37.879Z",
          "updated_at": "2017-11-07T19:45:27.746Z",
          "x": 10176,
          "y": 12805,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:45:27.746Z"
        },
        {
          "id": 744,
          "area_id": 19,
          "name": "Doctor's Office",
          "created_at": "2017-07-18T07:53:03.981Z",
          "updated_at": "2017-11-07T19:43:55.868Z",
          "x": 10160,
          "y": 12761,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:43:55.867Z"
        },
        {
          "id": 745,
          "area_id": 19,
          "name": "Pharmahug",
          "created_at": "2017-07-18T07:54:08.295Z",
          "updated_at": "2017-11-07T19:46:08.217Z",
          "x": 10144,
          "y": 12761,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:46:08.215Z"
        },
        {
          "id": 746,
          "area_id": 19,
          "name": "Mendy's Eatery",
          "created_at": "2017-07-18T07:54:26.449Z",
          "updated_at": "2017-11-07T19:45:11.716Z",
          "x": 10131,
          "y": 12761,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:45:11.716Z"
        },
        {
          "id": 748,
          "area_id": 19,
          "name": "Restaurant",
          "created_at": "2017-07-18T07:55:19.115Z",
          "updated_at": "2017-11-07T19:46:24.855Z",
          "x": 10095,
          "y": 12750,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:46:24.855Z"
        },
        {
          "id": 752,
          "area_id": 19,
          "name": "Family Fashion",
          "created_at": "2017-07-18T08:17:21.159Z",
          "updated_at": "2017-11-07T19:44:08.838Z",
          "x": 10079,
          "y": 12818,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:44:08.837Z"
        },
        {
          "id": 753,
          "area_id": 19,
          "name": "Bakery",
          "created_at": "2017-07-18T08:17:33.701Z",
          "updated_at": "2017-11-07T19:43:04.099Z",
          "x": 10081,
          "y": 12806,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:43:04.099Z"
        },
        {
          "id": 756,
          "area_id": 19,
          "name": "Dormitory",
          "created_at": "2017-07-18T08:48:08.049Z",
          "updated_at": "2017-11-07T19:43:59.562Z",
          "x": 10080,
          "y": 12637,
          "score": 0,
          "user_id": 141,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:43:59.561Z"
        },
        {
          "id": 795,
          "area_id": 19,
          "name": "Insurance Office",
          "created_at": "2017-10-14T02:58:43.626Z",
          "updated_at": "2017-11-07T19:44:42.966Z",
          "x": 10074,
          "y": 12776,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:44:42.966Z"
        },
        {
          "id": 800,
          "area_id": 19,
          "name": "Hair-Saloon",
          "created_at": "2017-10-14T03:02:38.608Z",
          "updated_at": "2017-11-07T19:44:35.143Z",
          "x": 10183,
          "y": 12819,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:44:35.142Z"
        },
        {
          "id": 801,
          "area_id": 19,
          "name": "Office",
          "created_at": "2017-10-14T03:03:17.756Z",
          "updated_at": "2017-11-07T19:45:43.269Z",
          "x": 10184,
          "y": 12793,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:45:43.268Z"
        },
        {
          "id": 802,
          "area_id": 19,
          "name": "Laundromat",
          "created_at": "2017-10-14T03:07:09.164Z",
          "updated_at": "2017-11-07T19:44:47.615Z",
          "x": 10183,
          "y": 12782,
          "score": 0,
          "user_id": 156,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-07T19:44:47.614Z"
        }
      ],
      "url": "/POI/maps/1/areas/19/pois"
    },
    {
      "id": 22,
      "name": "Riverside",
      "pois": [
        {
          "id": 842,
          "area_id": 22,
          "name": "Hardware Store",
          "created_at": "2017-11-10T08:59:26.103Z",
          "updated_at": "2017-11-28T08:38:21.137Z",
          "x": 6364,
          "y": 5327,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:38:21.137Z"
        },
        {
          "id": 843,
          "area_id": 22,
          "name": "Hotel",
          "created_at": "2017-11-10T08:59:54.569Z",
          "updated_at": "2017-11-28T08:38:28.843Z",
          "x": 6357,
          "y": 5259,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:38:28.843Z"
        },
        {
          "id": 844,
          "area_id": 22,
          "name": "Gated Community ",
          "created_at": "2017-11-10T09:01:14.778Z",
          "updated_at": "2017-11-28T08:38:01.100Z",
          "x": 6715,
          "y": 5483,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:38:01.098Z"
        },
        {
          "id": 845,
          "area_id": 22,
          "name": "Enigmabooks",
          "created_at": "2017-11-10T09:02:23.296Z",
          "updated_at": "2017-11-28T08:36:54.625Z",
          "x": 6437,
          "y": 5269,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:36:54.624Z"
        },
        {
          "id": 846,
          "area_id": 22,
          "name": "Toy Store",
          "created_at": "2017-11-10T09:03:43.267Z",
          "updated_at": "2017-11-28T08:36:16.134Z",
          "x": 6452,
          "y": 5298,
          "score": 10,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:36:16.134Z"
        },
        {
          "id": 847,
          "area_id": 22,
          "name": "Police Station",
          "created_at": "2017-11-10T09:05:11.266Z",
          "updated_at": "2017-11-28T08:35:48.536Z",
          "x": 6082,
          "y": 5261,
          "score": 30,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:35:47.619Z"
        },
        {
          "id": 848,
          "area_id": 22,
          "name": "Gas Station",
          "created_at": "2017-11-10T09:05:28.328Z",
          "updated_at": "2017-11-28T08:37:42.387Z",
          "x": 6083,
          "y": 5309,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:37:42.386Z"
        },
        {
          "id": 849,
          "area_id": 22,
          "name": "School",
          "created_at": "2017-11-10T09:06:35.837Z",
          "updated_at": "2017-11-28T08:39:26.813Z",
          "x": 6446,
          "y": 5444,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:39:26.813Z"
        },
        {
          "id": 850,
          "area_id": 22,
          "name": "Golf course",
          "created_at": "2017-11-10T09:09:01.180Z",
          "updated_at": "2017-11-28T08:38:14.155Z",
          "x": 6091,
          "y": 6392,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:38:14.154Z"
        },
        {
          "id": 851,
          "area_id": 22,
          "name": "Country club",
          "created_at": "2017-11-10T09:09:25.100Z",
          "updated_at": "2017-11-28T08:36:46.101Z",
          "x": 5756,
          "y": 6446,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:36:46.101Z"
        },
        {
          "id": 853,
          "area_id": 22,
          "name": "Food Market",
          "created_at": "2017-11-10T09:13:15.491Z",
          "updated_at": "2017-11-28T08:37:10.143Z",
          "x": 5969,
          "y": 5392,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:37:10.142Z"
        },
        {
          "id": 854,
          "area_id": 22,
          "name": "Bar",
          "created_at": "2017-11-10T09:13:47.104Z",
          "updated_at": "2017-11-28T08:36:30.399Z",
          "x": 5963,
          "y": 5422,
          "score": 0,
          "user_id": 161,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:36:30.399Z"
        },
        {
          "id": 855,
          "area_id": 22,
          "name": "Pharmacy",
          "created_at": "2017-11-11T10:55:45.431Z",
          "updated_at": "2017-11-28T08:35:38.376Z",
          "x": 6471,
          "y": 5266,
          "score": 30,
          "user_id": 162,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:35:37.417Z"
        },
        {
          "id": 857,
          "area_id": 22,
          "name": "Spiffo's",
          "created_at": "2017-11-11T16:05:27.804Z",
          "updated_at": "2017-11-28T08:36:07.790Z",
          "x": 6123,
          "y": 5305,
          "score": 30,
          "user_id": 162,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:36:06.821Z"
        },
        {
          "id": 859,
          "area_id": 22,
          "name": "Church",
          "created_at": "2017-11-11T16:06:59.643Z",
          "updated_at": "2017-11-28T08:36:36.750Z",
          "x": 6568,
          "y": 5374,
          "score": 0,
          "user_id": 162,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:36:36.750Z"
        },
        {
          "id": 860,
          "area_id": 22,
          "name": "School",
          "created_at": "2017-11-11T16:31:31.740Z",
          "updated_at": "2017-11-28T08:35:58.013Z",
          "x": 6440,
          "y": 5445,
          "score": 30,
          "user_id": 162,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:35:57.243Z"
        },
        {
          "id": 861,
          "area_id": 22,
          "name": "Laundromat",
          "created_at": "2017-11-11T16:32:55.316Z",
          "updated_at": "2017-11-28T08:38:47.756Z",
          "x": 6407,
          "y": 5339,
          "score": 0,
          "user_id": 162,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:38:47.755Z"
        },
        {
          "id": 862,
          "area_id": 22,
          "name": "Farm",
          "created_at": "2017-11-19T14:15:44.256Z",
          "updated_at": "2017-11-28T08:37:02.343Z",
          "x": 6817,
          "y": 7720,
          "score": 0,
          "user_id": 163,
          "comment": "Farm with field, two toolsheds",
          "approver_id": 2,
          "approved_at": "2017-11-28T08:37:02.342Z"
        },
        {
          "id": 869,
          "area_id": 22,
          "name": "Burgers",
          "created_at": "2017-12-24T02:54:01.111Z",
          "updated_at": "2018-05-11T11:13:24.861Z",
          "x": 5955,
          "y": 5263,
          "score": 20,
          "user_id": 167,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:24.861Z"
        },
        {
          "id": 870,
          "area_id": 22,
          "name": "Ice Cream Parlor",
          "created_at": "2017-12-29T21:49:56.365Z",
          "updated_at": "2018-05-11T11:15:17.936Z",
          "x": 6471,
          "y": 5222,
          "score": 0,
          "user_id": 167,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:15:17.936Z"
        },
        {
          "id": 873,
          "area_id": 22,
          "name": "Bank",
          "created_at": "2018-03-15T22:06:33.834Z",
          "updated_at": "2018-05-11T11:13:24.269Z",
          "x": 6504,
          "y": 5301,
          "score": 20,
          "user_id": 172,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:24.268Z"
        },
        {
          "id": 874,
          "area_id": 22,
          "name": "Clothing Store",
          "created_at": "2018-03-15T22:08:07.336Z",
          "updated_at": "2018-05-11T11:12:39.778Z",
          "x": 6403,
          "y": 5268,
          "score": 10,
          "user_id": 172,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:12:39.777Z"
        },
        {
          "id": 876,
          "area_id": 22,
          "name": "Clothing Store",
          "created_at": "2018-03-15T22:09:10.238Z",
          "updated_at": "2018-05-11T11:12:39.292Z",
          "x": 6506,
          "y": 5266,
          "score": 10,
          "user_id": 172,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:12:39.292Z"
        },
        {
          "id": 880,
          "area_id": 22,
          "name": "Farm Store",
          "created_at": "2018-03-30T20:48:16.914Z",
          "updated_at": "2018-05-11T11:14:00.895Z",
          "x": 7253,
          "y": 8285,
          "score": 0,
          "user_id": 174,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:14:00.894Z"
        },
        {
          "id": 881,
          "area_id": 22,
          "name": "Gigamart",
          "created_at": "2018-04-06T20:10:06.023Z",
          "updated_at": "2018-05-11T11:14:17.636Z",
          "x": 6505,
          "y": 5346,
          "score": 0,
          "user_id": 175,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:14:17.636Z"
        },
        {
          "id": 885,
          "area_id": 22,
          "name": "Self Storage",
          "created_at": "2018-05-07T23:54:30.155Z",
          "updated_at": "2018-05-11T11:14:54.128Z",
          "x": 5541,
          "y": 6063,
          "score": 0,
          "user_id": 184,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:14:54.125Z"
        },
        {
          "id": 886,
          "area_id": 22,
          "name": "Auto Repair",
          "created_at": "2018-05-07T23:55:54.424Z",
          "updated_at": "2018-05-11T11:13:23.308Z",
          "x": 5429,
          "y": 5964,
          "score": 20,
          "user_id": 184,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:23.306Z"
        },
        {
          "id": 887,
          "area_id": 22,
          "name": "Diner",
          "created_at": "2018-05-07T23:56:28.675Z",
          "updated_at": "2018-05-11T11:13:25.465Z",
          "x": 5424,
          "y": 5914,
          "score": 20,
          "user_id": 184,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:25.465Z"
        },
        {
          "id": 888,
          "area_id": 22,
          "name": "Gas Station",
          "created_at": "2018-05-07T23:57:23.119Z",
          "updated_at": "2018-05-11T11:14:13.098Z",
          "x": 5418,
          "y": 5870,
          "score": 0,
          "user_id": 184,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:14:13.098Z"
        },
        {
          "id": 889,
          "area_id": 22,
          "name": "Factory",
          "created_at": "2018-05-08T00:00:10.040Z",
          "updated_at": "2018-05-11T11:13:27.435Z",
          "x": 5570,
          "y": 5899,
          "score": 0,
          "user_id": 184,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:27.434Z"
        },
        {
          "id": 890,
          "area_id": 22,
          "name": "Factory Storage",
          "created_at": "2018-05-08T00:00:47.551Z",
          "updated_at": "2018-05-11T11:13:33.224Z",
          "x": 5617,
          "y": 5973,
          "score": 0,
          "user_id": 184,
          "comment": "",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:33.224Z"
        },
        {
          "id": 891,
          "area_id": 22,
          "name": "Farm",
          "created_at": "2018-05-08T00:03:40.623Z",
          "updated_at": "2018-05-11T11:13:55.844Z",
          "x": 5693,
          "y": 5740,
          "score": 0,
          "user_id": 184,
          "comment": "Has a lot of land, two houses and sheds.",
          "approver_id": 2,
          "approved_at": "2018-05-11T11:13:55.842Z"
        }
      ],
      "url": "/POI/maps/1/areas/22/pois"
    }
  ]
}
