import * as Sentry from "@sentry/browser";

/**
 * Error reporting
 */

export default function initSentry() {
  // https://parceljs.org/features/node-emulation/#environment-variables
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://1b04f6b1d85149609dcb700966d1df20@o1093615.ingest.sentry.io/6112951",

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: `map.six.ph@${process.env.REVISION}`,
    });
  }
}
