import * as React from "react";
import classNames from "classnames";
import LayerSelector, { LayerSelectorProps } from "./LayerSelector";

import "./Sidebar.scss";

export enum SidebarTabId {
  COORDINATE_TOOL = 1,
  NEWS_CHANGELOG,
  POIS,
}

interface SidebarTabProps {
  title: string;
  initiallyActive?: boolean;
  children: React.ReactNode;

  onActiveChange?: (isActive: boolean) => void;
}

const SidebarTab = function ({
  title,
  children,
  initiallyActive,
  onActiveChange,
}: SidebarTabProps) {
  const [active, setActive] = React.useState(initiallyActive);

  const onClick = (newActive: boolean) => {
    onActiveChange?.(newActive);
    setActive(!active);
  };

  return (
    <div className="panel panel-default">
      <div className="panel-heading" id="headingOne">
        <button
          className="link-button"
          type="button"
          onClick={() => onClick(!active)}
        >
          <h4 className="panel-title">{title}</h4>
        </button>
      </div>
      <div className={classNames("panel-collapse", { collapse: !active })}>
        <div className="panel-body">
          {children}
          {/* <!-- temp1.data.viewer.zones[0].zones.map(x => x.sum.bytes).reduce((a,b)=>a+b) --> */}
        </div>
      </div>
    </div>
  );
};

interface SidebarProps extends LayerSelectorProps {
  setCoordinateToolActive: (isActive: boolean) => void;
  setPoisEnabled: (enabled: boolean) => void;
}

const Sidebar = function ({
  setLayer,
  setCoordinateToolActive,
  setPoisEnabled,
}: SidebarProps) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-3 col-md-2 sidebar">
          <div className="panel-group">
            <div className="panel panel-default">
              <div className="panel-heading" role="tab" id="headingOne">
                <h4 className="panel-title">Stock Map</h4>
              </div>
              <div className="panel-body">
                <ul className="nav">
                  <li>
                    Knox Country <span className="badge">Build 41.65</span>
                  </li>
                  <li>
                    <b>Select layers</b>
                  </li>
                  <LayerSelector setLayer={setLayer} />
                </ul>
              </div>
            </div>
            <SidebarTab
              title="Coordinate Tool"
              onActiveChange={setCoordinateToolActive}
            >
              <ul className="nav">
                <li className="">
                  <span style={{ whiteSpace: "nowrap" }}>
                    Coords: <span id="tile-coords">0x0</span>
                  </span>
                </li>
                <li className="">
                  <span style={{ whiteSpace: "nowrap" }}>
                    Cell: <span id="cell-coords">0x0</span>
                  </span>
                </li>
                <li className="">
                  <span style={{ whiteSpace: "nowrap" }}>
                    Rel: <span id="rel-coords">0x0</span>
                  </span>
                </li>
                <li>(On layer 0)</li>
              </ul>
            </SidebarTab>

            <SidebarTab title="POIs" initiallyActive>
              <ul className="nav">
                <li className="">
                  <div>Show default POIs:</div>
                  <div
                    onChange={(e: any) =>
                      setPoisEnabled(e.target.value === "on")
                    }
                    className="SidebarTab__PoiToggler"
                  >
                    <label htmlFor="poi_toggle_on">
                      <input
                        type="radio"
                        value="on"
                        name="poi_toggle"
                        id="poi_toggle_on"
                        defaultChecked
                      />
                      On
                    </label>
                    <label htmlFor="poi_toggle_off">
                      <input
                        type="radio"
                        value="off"
                        name="poi_toggle"
                        id="poi_toggle_off"
                      />
                      Off
                    </label>
                  </div>
                </li>
              </ul>
            </SidebarTab>

            <SidebarTab title="News / Changelog" initiallyActive>
              <ul className="nav">
                <li>
                  (2022-01-18) Got some COVID and took some time to recover. :)
                  I&apos;m mostly planning on just keeping this site up in
                  maintenance mode after a few bugfixes. Thanks to everyone who
                  supported this map!
                </li>
                <li>
                  (2021-12-29) Looks like the official map.projectzomboid.com is
                  updated!
                </li>
                <li>
                  Current TODO list:
                  <ol>
                    <li>Look into Firefox POI issue</li>
                    <li>Add more POIs</li>
                    <li>Publish updated map generation code</li>
                    <li>Try to improve iOS performance</li>
                    <li>
                      Want some other cool feature? Msg me on Discord/Reddit!
                    </li>
                  </ol>
                </li>
              </ul>
            </SidebarTab>

            <SidebarTab title="Network Stats (2022-01-18)">
              <ul className="nav">
                <li>Network requests handled: 220.7 M</li>
                <li>Network data transferred: 30.4 TB</li>
                {/* <!-- temp1.data.viewer.zones[0].zones.map(x => x.sum.bytes).reduce((a,b)=>a+b) --> */}
              </ul>
            </SidebarTab>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
