import * as React from "react";
import * as ReactDOM from "react-dom";

import OpenSeadragon from "openseadragon";

import LicenseButton from "./LicenseButton";
import PoiManager from "./PoiMarker";
import Sidebar from "./Sidebar";
import { PoiOption } from "./poi";

interface AppProps {
  poiMount: HTMLDivElement;
  licenseMount: HTMLDivElement;
  sidebarMount: HTMLDivElement;

  getPois(): Promise<PoiOption[]>;

  setLayer: (choice: number) => Promise<void>;
  setCoordinateToolActive: () => void;

  viewer: OpenSeadragon.Viewer;
}
const App = function ({
  poiMount,
  licenseMount,
  sidebarMount,
  getPois,
  setLayer,
  setCoordinateToolActive,
  viewer,
}: AppProps) {
  const [poiOptions, setPoiOptions] = React.useState<PoiOption[]>([]);

  React.useEffect(() => {
    const getAndSetPois = async () => {
      const pois = await getPois();
      setPoiOptions(pois);
    };
    getAndSetPois();
  }, []);

  const [poisEnabled, setPoisEnabled] = React.useState(true);

  return [
    ReactDOM.createPortal(<LicenseButton />, licenseMount),
    ReactDOM.createPortal(
      <PoiManager
        viewer={viewer}
        poiOptions={poiOptions}
        enabled={poisEnabled}
      />,
      poiMount
    ),
    ReactDOM.createPortal(
      <Sidebar
        setLayer={setLayer}
        setCoordinateToolActive={setCoordinateToolActive}
        setPoisEnabled={setPoisEnabled}
      />,
      sidebarMount
    ),
  ];
};

export default App;
