import * as React from "react";
import * as ReactDOM from "react-dom";

import classNames from "classnames";
import OpenSeadragon from "openseadragon";

import { PoiOption } from "./poi";

import "./PoiMarker.scss";

interface SVGPoiMarkerProps {
  poiOption: PoiOption;
  active: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const SVGPoiMarker = function ({
  poiOption,
  active,
  onMouseEnter,
  onMouseLeave,
}: SVGPoiMarkerProps) {
  return (
    <g
      transform={`translate(${poiOption.location.x}, ${poiOption.location.y})`}
    >
      <circle
        r={18}
        strokeWidth={3}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="SVGPoiMarker"
      />

      <foreignObject
        x={0}
        y={24}
        height={1}
        width={1}
        className={classNames("SVGPoiMarker__ForeignObject", {
          SVGPoiMarker__ForeignObjectActive: active,
        })}
      >
        <div className="PoiMarker__Tooltip">
          <div className="PoiMarker__Tooltip__Name">{poiOption.name}</div>
          {poiOption.comment && (
            <div className="PoiMarker__Tooltip__Comment">
              {poiOption.comment}
            </div>
          )}
        </div>
      </foreignObject>
    </g>
  );
};

const svgNS = "http://www.w3.org/2000/svg";

interface PoiManagerProps {
  viewer: OpenSeadragon.Viewer;
  poiOptions: PoiOption[];
  enabled: boolean;
}

interface PoiManagerState {
  poiIdToElement: Record<string, SVGGElement>;
  activePoi?: number;
}

class PoiManager extends React.Component<PoiManagerProps, PoiManagerState> {
  constructor(props: PoiManagerProps) {
    super(props);
    this.state = { poiIdToElement: {} };
  }

  componentDidMount() {
    this.loadNewPoiOptions();
  }

  componentDidUpdate() {
    this.loadNewPoiOptions();
  }

  setActivePoi(activePoi: number | undefined) {
    this.setState({ activePoi });
  }

  loadNewPoiOptions() {
    const { viewer, poiOptions } = this.props;
    const { poiIdToElement } = this.state;

    const newState: typeof poiIdToElement = {};

    // TODO: support removing of POIs too

    poiOptions.forEach((poiOption) => {
      if (!(poiOption.id in poiIdToElement)) {
        const generatedId = `overlay-poi-${poiOption.id}`;

        const gElement = document.createElementNS(svgNS, "g");
        gElement.id = generatedId;

        (viewer.svgOverlay().node() as SVGGElement).appendChild(gElement);

        newState[poiOption.id] = gElement;
      }
    });

    if (Object.keys(newState).length > 0) {
      this.setState({
        poiIdToElement: { ...poiIdToElement, ...newState },
      });
    }
  }

  render() {
    const { enabled, poiOptions } = this.props;
    const { activePoi, poiIdToElement } = this.state;

    const renderablePoiOptions = poiOptions.filter(
      (poiOption) => poiOption.id in poiIdToElement
    );

    if (!enabled) {
      return null;
    }

    return (
      <>
        {renderablePoiOptions.map((poiOption) =>
          ReactDOM.createPortal(
            <SVGPoiMarker
              key={poiOption.id}
              active={poiOption.id === activePoi}
              poiOption={poiOption}
              onMouseEnter={() => this.setActivePoi(poiOption.id)}
              onMouseLeave={() => this.setActivePoi(undefined)}
            />,
            poiIdToElement[poiOption.id]
          )
        )}
      </>
    );
  }
}

export default PoiManager;
