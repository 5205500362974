import * as React from "react";

import "./LayerSelector.scss";

export enum ValidLayers {
  LAYER_0 = 0,
  LAYER_1 = 1,
  LAYER_2 = 2,
  LAYER_7 = 7,
}

export const ORDERED_LAYERS_AND_DESCRIPTIONS: [ValidLayers, string][] = [
  [ValidLayers.LAYER_0, "Layer 0 (Ground only)"],
  [ValidLayers.LAYER_1, "Layer 1"],
  [ValidLayers.LAYER_2, "Layer 2"],
  [ValidLayers.LAYER_7, "Layer 7 (All layers)"],
];

export interface LayerSelectorProps {
  setLayer: (newLayer: ValidLayers) => Promise<void>;
}

const LayerSelector = function ({ setLayer }: LayerSelectorProps) {
  const [activeLayer, setActiveLayer] = React.useState(ValidLayers.LAYER_0);
  const [enabled, setEnabled] = React.useState(true);

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = async (e) => {
    const newActiveLayer = parseInt(e.currentTarget.value, 10);
    setEnabled(false);
    setActiveLayer(newActiveLayer);
    await setLayer(newActiveLayer);
    setEnabled(true);
  };

  return (
    <div className="LayerSelector">
      <select
        id="select-layer"
        onChange={onChange}
        disabled={!enabled}
        value={activeLayer}
      >
        {ORDERED_LAYERS_AND_DESCRIPTIONS.map(([layer, description]) => (
          <option value={layer} key={layer}>
            {description}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LayerSelector;
